import React from "react";
import { Link } from "react-router-dom";
import TopMenuItem from "../Topmenu/TopMenuItem";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

const NotFound = () => (
  <div className="flex  justify-center h-screen items-center  w-full">
    <Helmet>
      <meta name="title" content="Error 404 - Page Not Found" />

      <meta name="description" content="Eror 404 - Page Not Found" />

      <title>Error 404 - Page Not Found</title>
    </Helmet>
    <div className="flex flex-col border-2 border-red items-center">
      <h1 className="text-red text-9xl">404 - Not Found!</h1>

      <Link to="/">
        <TopMenuItem icon={faHome} name="Retour au site" />
      </Link>
    </div>
  </div>
);

export default NotFound;
