import React, { useContext } from "react";
import { defaultContent } from "../../types/defaultContent";
import EditableTextPackage from "../editableLiveForm/EditableTextPackage";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  defaultTexts: defaultContent;
};

const Comments: React.FC<IProps> = ({ defaultTexts }) => {
  const { currentUser, isLoggedContextState } = useContext(AuthContext) as UserContextType;
  return (
    <div className="bg-gradient-to-r to-aqs-green-200 from-aqs-green-500 rounded-b-2xl rounded-3xl">
      <div className="p-5 flex justify-center">
        {isLoggedContextState && currentUser.admin === 1 ? (
          <div className="">
            <div className="m-3 p-3 text-center text-3xl">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"title"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="m-3 p-3 text-center">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"subtitle"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="m-3 p-3 text-center">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"description"}
                routeToPatch={"textContent"}
              />
            </div>
          </div>
        ) : (
          <div style={{ whiteSpace: "pre-line" }} className="">
            <div className="m-3 p-3 text-center text-3xl">
              <h3>{defaultTexts?.title}</h3>
            </div>
            <div className="m-3 p-3 text-center">
              <h3>{defaultTexts?.subtitle}</h3>
            </div>
            <div className="m-3 p-3 text-center">
              <h3>{defaultTexts?.description}</h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Comments;
