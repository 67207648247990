import React from "react";

type IProps = {
  valid: boolean;
  text?: string;
};

const ValidEntry: React.FC<IProps> = ({ valid, text = "remplir ce champs valide" }) => {
  return (
    <div>
      {valid ? (
        <p className="text-xs text-green/50">OK!</p>
      ) : (
        <p className="text-xs text-orange/50">{text}</p>
      )}
    </div>
  );
};

export default ValidEntry;
