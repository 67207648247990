export const hideCardNumber = (card: string) => {
  let hiddenCard = "";
  if (card) {
    for (var i = 0; i < card.length; i++) {
      if (i > 3 && i < 12) {
        hiddenCard = hiddenCard + "X";
      } else {
        hiddenCard = hiddenCard + card[i];
      }
      if ((i + 1) % 4 === 0) {
        hiddenCard = hiddenCard + " ";
      }
    }
  }

  return hiddenCard;
};
