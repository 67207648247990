import React, { useState, useContext } from "react";
import { deleteInventory } from "../../services/inventoryService";
import { inventory } from "../../types/inventoryType";
import EditableInputFirstName from "../editableLiveForm/EditableInputFirstName";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  inventory: inventory;
  // eslint-disable-next-line no-unused-vars
  setInventories: (inventory: inventory[]) => void;
  inventories: inventory[];
};

const Journee: React.FC<IProps> = ({ inventory, setInventories, inventories }) => {
  const [inventoryState] = useState<inventory>(inventory);
  const { currentUser } = useContext(AuthContext) as UserContextType;

  const deleteJournee = async () => {
    await deleteInventory(inventoryState.ID, currentUser.id);
    alert(`La journee ID : ${inventoryState.ID} à été supprimé !`);
    inventories.forEach((inventori, index) => {
      if (inventori.ID === inventoryState.ID) {
        inventories.splice(index, 1);
      }
    });

    setInventories([...inventories]);
  };

  return (
    <div className="flex flex-row bg-white rounded-xl m-3 p-3">
      <div className="w-[30%] pt-10">
        <img
          className="h-auto w-full p-4"
          src="http://www.aventurequatresaisons.com/images/canotact.jpg"
          alt="???"
        />
      </div>
      <div className="flex flex-col w-[70%]">
        <div className="flex justify-end -mb-4">
          <FontAwesomeIcon onClick={deleteJournee} icon={faTrash} />
        </div>

        <div className="flex flex-wrap rounded border-2 bg-aqs-blue-300/30 border-aqs-green-400 m-4 justify-around">
          <div className="font-yeseva text-2xl mr-4">
            <p>Date : </p>
            <EditableInputFirstName
              key={uuidv4()}
              resaId={inventoryState.ID.toString()}
              fieldToChange={"startDate"}
              newValue={inventoryState.startDate}
            />
          </div>
          <div className="font-yeseva text-2xl mr-4">
            <p>Id Journée : </p>
            <EditableInputFirstName
              key={uuidv4()}
              resaId={inventoryState.ID.toString()}
              fieldToChange={"ID"}
              newValue={inventoryState.ID.toString()}
            />
          </div>
        </div>

        <div className="-mb-4">
          <p>Embarcations Restantes</p>
        </div>
        <div className="flex flex-wrap justify-center rounded border-2 bg-aqs-blue-300/10 border-aqs-green-400 m-4">
          <div className="font-yeseva text-1xl mr-4">
            <p>
              canot(s) :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"canoe"}
                newValue={inventoryState.canoe.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              kayak(s) :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"kayak"}
                newValue={inventoryState.kayak.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              sup(s) :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"sup"}
                newValue={inventoryState.sup.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
        </div>

        <div className="-mb-4">
          <p>Places Restantes</p>
        </div>
        <div className="flex flex-wrap justify-center rounded border-2 bg-aqs-blue-300/10 border-aqs-green-400 m-4">
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 9h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"spotLeft9"}
                newValue={inventoryState.spotLeft9.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 10h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"spotLeft10"}
                newValue={inventoryState.spotLeft10.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 11h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"spotLeft11"}
                newValue={inventoryState.spotLeft11.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 12h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"spotLeft12"}
                newValue={inventoryState.spotLeft12.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 13h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"spotLeft13"}
                newValue={inventoryState.spotLeft13.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 14h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"spotLeft14"}
                newValue={inventoryState.spotLeft14.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
        </div>

        <div className="-mb-4">
          <p>Personnes sur le départ :</p>
        </div>
        <div className="flex flex-wrap justify-center rounded border-2 bg-aqs-blue-300/10 border-aqs-green-400 m-4">
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 9h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"nbPersSurDepRed9"}
                newValue={inventoryState.nbPersSurDepRed9.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 10h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"nbPersSurDepRed10"}
                newValue={inventoryState.nbPersSurDepRed10.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 11h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"nbPersSurDepRed11"}
                newValue={inventoryState.nbPersSurDepRed11.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 12h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"nbPersSurDepRed12"}
                newValue={inventoryState.nbPersSurDepRed12.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 13h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"nbPersSurDepRed13"}
                newValue={inventoryState.nbPersSurDepRed13.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              bus 14h :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"nbPersSurDepRed14"}
                newValue={inventoryState.nbPersSurDepRed14.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
        </div>

        <div className="-mb-4">
          <p>Yourtes :</p>
        </div>
        <div className="flex flex-wrap justify-center rounded border-2 bg-aqs-blue-300/10 border-aqs-green-400 m-4">
          <div className="font-yeseva text-1xl mr-4">
            <p>
              Voyageuse :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"yourteVoyageuse"}
                newValue={inventoryState.yourteVoyageuse.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              Traditionnelle :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"yourteTrad"}
                newValue={inventoryState.yourteTrad.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p>
              Perchée :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={inventoryState.ID.toString()}
                fieldToChange={"yourtePerchee"}
                newValue={inventoryState.yourtePerchee.toString()}
                ApiCallType={"journee"}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Journee;
