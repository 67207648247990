import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
};

const OpenBookingMenuButton: React.FC<IProps> = ({ hideShowBookingBox }) => {
  const { language } = useContext(AuthContext) as UserContextType;
  return (
    <div className="m-3 p-3 flex sm:justify-center items-center text-center text-xl">
      <div className="fixed top-44 left-8 right-0 opacity-0 hidden">
        <div className="fixed sm:bg-white h-screen w-screen -left-2 top-0 opacity-50"></div>
        {/* <Reserver key={uuidv4()} hideBookingBox={hideShowBookingBox}></Reserver>  */}
        {/* <div className="w-96 h-96 bg-red left-1/2"></div> */}
      </div>
      <a href="#booking">
        <div
          onClick={hideShowBookingBox}
          className="border-2 border-white rounded-md sm:w-80 w-56 hover:animate-pulse cursor-pointer hover:scale-[110%] duration-1000">
          {language === "Eng" ? "Book Online !" : "Réservez en ligne!"}
        </div>
      </a>
    </div>
  );
};

export default OpenBookingMenuButton;
