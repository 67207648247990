import React, { useContext, useEffect, useRef, useState } from "react";
import { TalkToUser } from "./../helper/TalkToUser";
import { user, userValidation } from "../../types/userType";
import { signup } from "../../services/userService";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";
import signUp from "../../images/signinCrop.jpg";
import { emptyUser } from "../helper/defaultDataContent";
import ValidEntry from "./ValidEntry";

type IProps = {
  handleClose: () => void;
  showModal: boolean;
};

const Signup: React.FC<IProps> = ({ handleClose }) => {
  const { updateUser } = useContext(AuthContext) as UserContextType;
  const [newUser, setUser] = useState<user>(emptyUser);
  const [confirmPassword, setConfirmPassord] = useState<string>("");
  const [passwordConfirmationOK, setPasswordConfirmationOK] = useState<boolean>(false);
  const refPasswordConfirm = useRef<HTMLInputElement>(null);

  useEffect(() => {}, [newUser]);
  const [userValidation, setUserValidation] = useState<userValidation | any>({
    username: false,
    firstName: false,
    lastName: false,
    email: false,
    telephone: false,
    password: false
  });

  const checkAllForm = (): boolean => {
    const allRegExpOk = Object.keys(userValidation).every((k) => userValidation[k]); //Validate if all keys are true

    setPasswordConfirmationOK(true);

    let formAllGood = true;
    if (
      newUser.username === undefined ||
      newUser.firstName === undefined ||
      newUser.lastName === undefined ||
      newUser.email === undefined ||
      newUser.telephone === undefined ||
      newUser.password === undefined
    ) {
      formAllGood = false;
    }
    if (!allRegExpOk) {
      formAllGood = false;
    }
    if (confirmPassword != newUser.password) {
      formAllGood = false;
      setPasswordConfirmationOK(false);
    }

    return formAllGood;
  };

  const setPasswordConfirm = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfirmPassord(event.target.value);
  };

  useEffect(() => {
    checkAllForm();
  }, [confirmPassword, newUser.password]);

  useEffect(() => {
    if (passwordConfirmationOK) {
      refPasswordConfirm.current!.className =
        "border-2 border-aqs-green-100 rounded-md p-1 w-full invalid:border-2 bg-green invalid:border-red/70 invalid:bg-red/10 valid:border-[1px] valid:border-green valid:bg-green/10";
    } else {
      refPasswordConfirm.current!.className =
        "border-2 border-aqs-green-100 rounded-md p-1 w-full border-2 bg-green border-red/70 bg-red/10 ";
    }
  }, [passwordConfirmationOK]);

  const setNewUser = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const userTmp = { ...newUser, [event.target.id]: event.target.value };
    let validationUserTmp = { ...userValidation, [event.target.id]: true };

    if (event.target.validity.patternMismatch) {
      validationUserTmp = { ...userValidation, [event.target.id]: false };
    }

    if (event.target.validity.valueMissing) {
      validationUserTmp = { ...userValidation, [event.target.id]: false };
    }

    setUser(userTmp);
    setUserValidation(validationUserTmp);
  };

  const sendSub = async () => {
    if (checkAllForm()) {
      try {
        const loggedUser = await signup(newUser);
        updateUser(loggedUser);
        handleClose();
      } catch (error) {
        TalkToUser(error as string);
      }
    } else {
      TalkToUser(
        `Vos champs sont mal remplis !
            USERNAME : 8 - 30 char
            PASS : min 9 char
            NAME and PRENAME: min 3 char
            EMAIL : xx@xx.xx
            PHONE : 123-123-1234 - (123) 123-1323`
      );
    }
  };

  return (
    <div className="absolute top-[10%] opacity-100 z-40 w-[100%] sm:w-[500px]">
      <div className="bg-gradient-to-b to-aqs-blue-500 from-aqs-green-50 border-4 rounded-md text-black text-xl">
        <div className="m-4 p-3 flex flex-row justify-between">
          <div
            onClick={handleClose}
            className=" hover:bg-aqs-green-700/50  rounded-md cursor-pointer p-2">
            <p>X</p>
          </div>
          <div className="text-4xl">
            <p>SIGNUP</p>
          </div>
          <div></div>
        </div>
        <div className="m-3">
          <img id="logoTopMenu" src={signUp} alt="Nos chiens de traineau au travail!" />
        </div>
        <form>
          <div className="border-2 border-black m-4 p-3">
            <div>
              <div className="p-2">
                <label className="">Username:</label>
              </div>
              <div className="p-2">
                <input
                  className="border-2 border-aqs-green-100 rounded-md p-1 w-full invalid:border-2 invalid:border-red/70 invalid:bg-red/10 valid:border-[1px] valid:border-green valid:bg-green/10"
                  id={"username"}
                  onChange={setNewUser}
                  type="text"
                  pattern="^[A-Za-z][A-Za-z0-9_]{7,29}$"
                  name="username"
                  placeholder={"Username"}
                  value={newUser.username}
                  required
                />
                <ValidEntry valid={userValidation.username} />
              </div>
            </div>
            <div className="">
              <div className="p-2">
                <label className="">Password:</label>
              </div>
              <div className="p-2">
                <input
                  className="border-2 border-aqs-green-100 rounded-md p-1 w-full invalid:border-2 invalid:border-red/70 invalid:bg-red/10 valid:border-[1px] valid:border-green valid:bg-green/10"
                  id={"password"}
                  onChange={setNewUser}
                  type="password"
                  name="password"
                  pattern="[\d\D]{9,}"
                  placeholder={"Password"}
                  value={newUser.password}
                  required
                />
                <ValidEntry valid={userValidation.password} />
              </div>
            </div>
            <div className="">
              <div className="p-2">
                <label className="">Confirm password:</label>
              </div>
              <div className="p-2">
                <input
                  className="border-2 border-aqs-green-100 rounded-md p-1 w-full invalid:border-2 invalid:border-red/70 valid:border-[1px] valid:border-green"
                  ref={refPasswordConfirm}
                  id={"passwordConfirm"}
                  onChange={setPasswordConfirm}
                  type="password"
                  name="passwordConfirm"
                  pattern="[\d\D]{9,}"
                  placeholder={"Confirm password here"}
                  value={confirmPassword}
                  required
                />
                <ValidEntry valid={passwordConfirmationOK} />
              </div>
            </div>
          </div>
          <div className="border-2 border-black m-4 p-3">
            <div className="">
              <div className="p-2">
                <label className="">FirstName:</label>
              </div>
              <div className="p-2">
                <input
                  className="border-2 border-aqs-green-100 rounded-md p-1 w-full invalid:border-2 invalid:border-red/70 invalid:bg-red/10 valid:border-[1px] valid:border-green valid:bg-green/10"
                  id={"firstName"}
                  onChange={setNewUser}
                  pattern="[^0-9]{3,29}"
                  type="text"
                  name="firstName"
                  placeholder={"FirstName"}
                  value={newUser.firstName}
                  required
                />
                <ValidEntry valid={userValidation.firstName} />
              </div>
            </div>
            <div className="">
              <div className="p-2">
                <label className="">LastName:</label>
              </div>
              <div className="p-2">
                <input
                  className="border-2 border-aqs-green-100 rounded-md p-1 w-full invalid:border-2 invalid:border-red/70 invalid:bg-red/10 valid:border-[1px] valid:border-green valid:bg-green/10"
                  id={"lastName"}
                  onChange={setNewUser}
                  pattern="[^0-9]{3,29}"
                  type="text"
                  name="lastName"
                  placeholder={"LastName"}
                  value={newUser.lastName}
                  required
                />
                <ValidEntry valid={userValidation.lastName} />
              </div>
            </div>
          </div>
          <div className="border-2 border-black m-4 p-3">
            <div className="">
              <div className="p-2">
                <label className="">Email:</label>
              </div>
              <div className="p-2">
                <input
                  className="border-2 border-aqs-green-100 rounded-md p-1 w-full invalid:border-2 bg-green invalid:border-red/70 invalid:bg-red/10 valid:border-[1px] valid:border-green valid:bg-green/10"
                  id={"email"}
                  onChange={setNewUser}
                  type="text"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  name="email"
                  placeholder={"Email"}
                  value={newUser.email}
                  required
                />
                <ValidEntry valid={userValidation.email} />
              </div>
            </div>
            <div className=" ">
              <div className="p-2">
                <label className="">Telephone:</label>
              </div>
              <div className="p-2">
                <input
                  className="border-2 border-aqs-green-100 rounded-md p-1 w-full invalid:border-2 invalid:border-red/70 invalid:bg-red/10 valid:border-[1px] valid:border-green valid:bg-green/10"
                  id={"telephone"}
                  onChange={setNewUser}
                  type="tel"
                  pattern="\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$"
                  name="telephone"
                  placeholder={"Telephone"}
                  value={newUser.telephone}
                  required
                />
                <ValidEntry valid={userValidation.telephone} />
              </div>
            </div>
          </div>
        </form>
        <div className="flex justify-center">
          <div
            onClick={sendSub}
            className="border-2 border-black m-4 p-3 flex justify-center bg-aqs-green-300 hover:bg-aqs-green-100 hover:cursor-pointer rounded-lg w-[25%]">
            <button>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
