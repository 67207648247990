// const URL = "http://localhost:3010";

import { TalkToUser } from "../components/helper/TalkToUser";
import { getCookie } from "../components/helper/getcookies";

// eslint-disable-next-line no-undef
const URL = process.env.REACT_APP_API_BASE_URL;

export const postInventoryForDate = async <inventory>(payloadDate: string): Promise<inventory> => {
  const response = await fetch(`${URL}/inventory/searchByDate/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ startDate: payloadDate })
  });

  const data = await response.json();

  if (response.status === 429) {
    TalkToUser(`[NOT_FOUND] - Date ${response.status}, ${response.statusText}, ${data.message}`);
    throw new Error(data.message);
  }
  if (!response.ok) {
    TalkToUser(
      `Can't acces to server !  ${response.status}, ${response.statusText}, ${data.message}`
    );
    throw new Error(data.message);
  }

  try {
    return data;
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getFullInventory = async <inventory>(): Promise<inventory[]> => {
  const response = await fetch(`${URL}/inventory/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};
export const deleteInventory = async (inventoryId: number, currentUser: string) => {
  const response = await fetch(`${URL}/inventory/deleteInventory/`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN"),
      "x-userid": currentUser
    },
    body: JSON.stringify({ ID: inventoryId })
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

export const patchInventoryInfo = async <inventory>(
  resaId: string,
  newInfo: inventory,
  currentUser: string
) => {
  // Ajouter le ID ou patche by name du UserContext

  const payload = { ...newInfo, ID: resaId };

  const response = await fetch(`${URL}/inventory/patchInventory/`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN"),
      "x-userid": currentUser
    },
    body: JSON.stringify(payload)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    // alert(`Votre modification à été apporté ! nouvel Entrée : ${Object.values(newInfo)[0]}`);
  }
};
