import React, { useContext, useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import { getPackagesCanoe } from "../../services/packageService";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { canoePackages } from "../helper/defaultDataContent";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
  languageUrl?: string;
};

const Canot: React.FC<IProps> = ({ languageUrl = "", hideShowBookingBox }) => {
  const { setNewLanguage, language } = useContext(AuthContext) as UserContextType;
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(canoePackages);
  const title = language === "Eng" ? "Canoe" : "Canot";

  // ***-------------------Retrieving Activity-----------------------------------***

  useEffect(() => {
    if (languageUrl === "Eng") {
      setNewLanguage("Eng");
    } else {
      setNewLanguage("");
    }
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesCanoe();
      setPackagesCanoeState(fetchedPackagesCanoe);
    } catch (err) {
      throw new Error(err as string);
    }
  };
  // END---------------------Retrieving Activity-----------------------------------***
  return (
    <div className="z-20 w-full">
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng"
              ? "Canoeing in Rivière-Rouge, Mont-Tremblant"
              : "Canot à Rivière-Rouge, Mont-Tremblant"
          }
        />

        <meta
          name="description"
          content={
            language === "Eng"
              ? "Canoe on the Rivière-Rouge, Mont-Tremblant - Laurentides Canoe on the magnificent Rivière-Rouge"
              : "Descente en canot sur la Rivière-Rouge, Mont-Tremblant, Laurentides"
          }
        />

        <title>
          {language === "Eng"
            ? "Canoeing in Rivière-Rouge, Mont-Tremblant"
            : "Canot à Rivière-Rouge, Mont-Tremblant"}
        </title>
      </Helmet>
      <h1 className="hidden">
        {language === "Eng"
          ? "Canoeing in Rivière-Rouge, Mont-Tremblant"
          : "Canot à Rivière-Rouge, Mont-Tremblant"}
      </h1>
      <MainContentPage
        title={title}
        packagesCanoe={packagesCanoeState}
        hideShowBookingBox={hideShowBookingBox}></MainContentPage>
    </div>
  );
};

export default Canot;
