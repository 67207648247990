import React, { useState, useContext } from "react";
import { deleteResaDb } from "../../services/bookingService";
import { reservation } from "../../types/reservationTypes";
import EditableInputFirstName from "../editableLiveForm/EditableInputFirstName";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  reservation: reservation;
  // eslint-disable-next-line no-unused-vars
  setResas: (resas: reservation[]) => void;
  resas: reservation[];
};

const Master: React.FC<IProps> = ({ reservation, setResas, resas }) => {
  const [reseration] = useState<reservation>(reservation);
  const { currentUser } = useContext(AuthContext) as UserContextType;

  const deleteResa = async () => {
    await deleteResaDb(reseration.ID, currentUser.id);
    alert(`La réservation ID : ${reseration.ID} à été supprimé !`);
    resas.forEach((resa, index) => {
      if (resa.ID === reseration.ID) {
        resas.splice(index, 1);
      }
    });

    setResas([...resas]);
  };

  return (
    <div className=" ">
      <div className="flex flex-wrap content-end pl-8 bg-gray border-b-2 rounded border-r-2 border-y-electric-violet-300 borde border-x-electric-violet-700">
        {/* <div className="font-yeseva text-1xl mr-4"><EditableInputFirstName key={uuidv4()} resaId={reseration.ID} fieldToChange={"startDate"} newValue={reseration.startDate} fixedSized={true}/></div> */}
        <div className="font-yeseva text-1xl mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"departureTime"}
            newValue={reseration.departureTime.toString()}
            txtAddon={"H"}
          />
        </div>
        <div className="font-yeseva text-xs  mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"activite"}
            newValue={reseration.activite}
            fixedSized={true}
          />
        </div>
        <div className="font-yeseva text-xl mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"name"}
            newValue={reseration.name}
            fixedSized={true}
          />
        </div>
        <div className="font-yeseva text-1xl mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"prename"}
            newValue={reseration.prename}
            fixedSized={true}
          />
        </div>
        <div className="font-yeseva text-xs  mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"nbPaxAd"}
            newValue={reseration.nbPaxAd.toString()}
            fixedSized={false}
            txtAddon={"Adu."}
          />
        </div>
        <div className="font-yeseva text-xs  mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"nbPaxEnf"}
            newValue={reseration.nbPaxEnf.toString()}
            fixedSized={false}
            txtAddon={"Enf."}
          />
        </div>
        <div className="font-yeseva text-xs  mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"nbCanoe"}
            newValue={reseration.nbCanoe.toString()}
            fixedSized={false}
            txtAddon={"Canots"}
          />
        </div>
        <div className="font-yeseva text-xs  mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"nbKayak"}
            newValue={reseration.nbKayak.toString()}
            fixedSized={false}
            txtAddon={"Kayaks"}
          />
        </div>
        <div className="font-yeseva text-xs  mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"nbSUP"}
            newValue={reseration.nbSUP.toString()}
            fixedSized={false}
            txtAddon={"SUPs"}
          />
        </div>
        <div className="font-yeseva text-xs  mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"duree"}
            newValue={reseration.duree.toString()}
            fixedSized={false}
            txtAddon={"jour(s)"}
          />
        </div>
        <div className="font-yeseva text-xs  mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"pricing"}
            newValue={(reseration.pricing * 1.15).toFixed(2).toString()}
            fixedSized2={true}
            txtAddon={"$"}
          />
        </div>
        <div className="font-yeseva text-xs  mr-4">
          <EditableInputFirstName
            key={uuidv4()}
            resaId={reseration.ID}
            fieldToChange={"specialCode"}
            newValue={reseration.specialCode.toString()}
            fixedSized={false}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <div className="-mt-8 mr-8">
          <FontAwesomeIcon onClick={deleteResa} icon={faTrash} />
        </div>
      </div>
    </div>
  );
};

export default Master;
