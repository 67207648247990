/* eslint-disable react/jsx-key */
// eslint-disable-next-line no-use-before-define
import React, { useContext, useEffect, useState } from "react";
import { getFullInventory } from "../../services/inventoryService";
import Journee from "./Journee";
import { v4 as uuidv4 } from "uuid";
import { inventory } from "../../types/inventoryType";
import LeftMenu from "./LeftMenu";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";
import NotFound from "../helper/NotFound";

const JourneeList: React.FC = () => {
  const [inventories, setInventories] = useState<inventory[]>([]);
  const { currentUser, isLoggedContextState } = useContext(AuthContext) as UserContextType;

  useEffect(() => {
    getJournee();
  }, []);

  const getJournee = async () => {
    try {
      const fetchedJournee: inventory[] = await getFullInventory();
      setInventories(fetchedJournee);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  return (
    <div className="w-full">
      {isLoggedContextState && currentUser.admin === 1 ? (
        <div
          id="wall"
          className="flex ml-3 mr-1 pr-3 w-full scrollbar-thin scrollbar-thumb-white scrollbar-track-gray overflow-y-scroll">
          <div className="w-[10%]">
            <LeftMenu />
          </div>
          <div className="w-[90%]">
            {inventories.map((inventory: inventory) => (
              <Journee
                key={uuidv4()}
                inventory={inventory}
                setInventories={setInventories}
                inventories={inventories}
              />
            ))}
          </div>
        </div>
      ) : (
        <div>
          <NotFound />
        </div>
      )}
    </div>
  );
};

export default JourneeList;
