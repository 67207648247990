import React, { useEffect, useState } from "react";
import { defaultContent } from "../../types/defaultContent";
import { contentTextDefault } from "../helper/defaultDataContent";
import { getDefaultContents } from "../../services/defaultContentService";

type IProps = {
  policyBoxToggle: () => void;
};

const BookingPolicyCheckout: React.FC<IProps> = ({ policyBoxToggle }) => {
  // ***-------------------Retrieving mainContent-----------------------------------***
  const [fetchedTextContent, setFetchedTextContent] =
    useState<defaultContent[]>(contentTextDefault);

  const getDefaultContentsTexts = async () => {
    try {
      const fetchedDefaultContents: defaultContent[] = await getDefaultContents();
      setFetchedTextContent(fetchedDefaultContents);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  useEffect(() => {
    getDefaultContentsTexts();
  }, []);

  useEffect(() => {}, [fetchedTextContent]);

  // END-------------------Retrieving mainContent-----------------------------------***
  return (
    <div className="" id="policy">
      <div className="flex justify-center text-4xl p-3 ">
        <p>{fetchedTextContent[1].subtitle}</p>
      </div>
      <div className="flex p-3 text-2xl mx-16 my-6">
        <p>{fetchedTextContent[1].description}</p>
      </div>
      <div className="flex p-3 text-2xl mx-16 my-6">
        <p>{fetchedTextContent[1].descriptionFollowUp}</p>
      </div>
      <div className="flex justify-center font-bold p-3 text-2xl mx-16 my-6">
        <p>{fetchedTextContent[1].secondTitle}</p>
      </div>
      <div className="flex p-3 text-2xl mx-16 my-6">
        <p>{fetchedTextContent[1].secondDescription}</p>
      </div>
      <div
        className="flex justify-center p-3 border-2 rounded-md m-4 hover:cursor-pointer hover:bg-white/50"
        onClick={policyBoxToggle}>
        <p>Fermer</p>
      </div>
    </div>
  );
};

export default BookingPolicyCheckout;
