import React from "react";
// import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// eslint-disable-next-line react/no-deprecated
import { hydrate, render } from "react-dom";
import ReactGA from "react-ga4";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

//Initialize GA4
ReactGA.initialize("G-S13Q39KNTG");

if (rootElement?.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname
  });
};

reportWebVitals(SendAnalytics);
