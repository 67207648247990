/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  faHome,
  faCalendar,
  faSun,
  faSnowflake,
  faPhone,
  faMap,
  faAward,
  faUser,
  faSignInAlt
} from "@fortawesome/free-solid-svg-icons";
import TopMenuItem from "./TopMenuItem";
import logoAQS from "../../images/logoaqsp.png";
import MenuDropDown from "../Topmenu/MenuDropDown";
import { activities } from "../../types/activityType";
import facebook from "../../../src/images/facebook.svg";
import twitter from "../../../src/images/twitter.svg";
import instagram from "../../../src/images/instagram.svg";
import { summerActivities, winterActivities, yourteActivities } from "../helper/defaultDataContent";
import { UserContextType } from "../../types/userType";
import { AuthContext } from "../../contexts/AuthProvider";
import Signup from "../authenticate/Signup";
import Login from "../authenticate/Login";
import Logout from "../authenticate/Logout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getActivitiesCardsSummer,
  getActivitiesCardsSummerEng,
  getActivitiesCardsWinter,
  getActivitiesCardsWinterEng,
  getActivitiesCardsYourte,
  getActivitiesCardsYourteEng
} from "../../services/activityService";

type IProps = {
  bookingState: boolean;
  dropDownState: boolean;
  hideShowBookingBox: () => void;
};

const TopMenu: React.FC<IProps> = ({ hideShowBookingBox }) => {
  // ***-------------------Authentication acces------------------------------------***
  const { currentUser, isLoggedContextState, language, toggleLanguage } = useContext(
    AuthContext
  ) as UserContextType;

  // END-------------------Authentication acces-----------------------------------***

  // ***-------------------Responsivenss const-----------------------------------***

  const [width, setWidth] = React.useState(window.innerWidth);

  const breakpoint = 1100;
  const AreWeMobile = (): Boolean => {
    if (width < breakpoint) {
      return true;
    } else {
      return false;
    }
  };
  const [areWeMobileState, setAreWeMobileState] = React.useState<Boolean>(AreWeMobile());

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (AreWeMobile() !== areWeMobileState) {
      setAreWeMobileState(AreWeMobile());
      showHideMenu(!areWeMobileState);
    }
  }, [width]);

  useEffect(() => {
    showHideMenu(!areWeMobileState);
  }, [areWeMobileState]);

  // END-------------------Responsivenss const-----------------------------------***

  // ***-------------------Show Menu-----------------------------------***
  const refMenu = useRef<HTMLDivElement>(null);
  const [menuShow, setmenuShow] = useState(!areWeMobileState);

  useEffect(() => {
    adjustMenuViewOnMobile();
  }, [menuShow]);

  const adjustMenuViewOnMobile = (): void => {
    if (menuShow) {
      if (areWeMobileState) {
        refMenu.current!.className =
          " flex sm:flex-row flex-col m-1 border-[0px] border-aqs-blue-200 bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[440px] transition-all duration-1000 z-30";
      } else {
        refMenu.current!.className = " flex sm:flex-row flex-col ";
      }
    } else {
      refMenu.current!.className =
        "flex flex-col sm:flex-row h-0 opacity-0 bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  transition-all duration-1000 -z-10";
    }
  };

  const toggleMenu = (): void => {
    if (menuShow) {
      setmenuShow(false);
    } else {
      setmenuShow(true);
    }
    setHoverSummer(false);
    setHoverWinter(false);
    setHoverYourte(false);
  };
  const showHideMenu = (state: Boolean): void => {
    state ? setmenuShow(true) : setmenuShow(false);
  };
  // END-------------------Show Menu-----------------------------------***
  // ***-------------------Summer Menu-----------------------------------***
  const [hoverSummer, setHoverSummer] = useState(false);
  const [activitiesSummer, setActivitiesSummer] = useState<activities[]>(summerActivities);
  const [activitiesSummerEng, setActivitiesSummerEng] = useState<activities[]>(summerActivities);
  const boxSummer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoverSummer) {
      if (areWeMobileState) {
        ForceIosWinterMenuAnimation();
        ForceIosYourteMenuAnimation();
        boxSummer.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-[220px] ease-in-out";
      } else {
        boxSummer.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-96 left-0";
      }
    }
    if (!hoverSummer) {
      if (areWeMobileState) {
        boxSummer.current!.className =
          "relative visible duration-1000 opacity-0 z-0 h-0 ease-in-out";
      } else {
        boxSummer.current!.className =
          " relative invisible -left-96 duration-500 opacity-0 sm:-ml-96 ml-96 h-0";
      }
    }
  }, [hoverSummer]);

  const toggleSummer = (): void => {
    refMenu.current!.className =
      " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[440px] transition-all duration-1000";

    if (hoverSummer) {
      setHoverSummer(false);
    }
    if (!hoverSummer) {
      //Resizing menu with new dropmenu
      refMenu.current!.className =
        " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[660px] transition-all duration-1000";
      setHoverSummer(true);
    }
  };
  const showSummer = (): void => {
    setHoverSummer(true);
  };
  const hideSummer = (): void => {
    if (areWeMobileState) {
      //Timeout so the LINK TO in ActMenuCard Can trigger before its being hide
      setTimeout(() => {
        setHoverSummer(false);
      }, 100);
    } else {
      setHoverSummer(false);
    }
  };
  // END-------------------Summer Menu-----------------------------------***

  // ***-------------------Winter Menu-----------------------------------***
  const [hoverWinter, setHoverWinter] = useState(false);
  const [activitiesWinter, setActivitiesWinter] = useState<activities[]>(winterActivities);
  const [activitiesWinterEng, setActivitiesWinterEng] = useState<activities[]>(winterActivities);
  const boxWinter = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoverWinter) {
      if (areWeMobileState) {
        ForceIosSummerMenuAnimation();
        ForceIosYourteMenuAnimation();
        boxWinter.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-[170px] ease-in-out";
      } else {
        boxWinter.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-96 left-0";
      }
    }
    if (!hoverWinter) {
      if (areWeMobileState) {
        boxWinter.current!.className =
          "relative visible duration-1000 opacity-0 z-0 h-0 ease-in-out";
      } else {
        boxWinter.current!.className =
          " relative invisible -left-96 duration-500 opacity-0 sm:-ml-96 ml-96 h-0";
      }
    }
  }, [hoverWinter]);

  const toggleWinter = (): void => {
    if (hoverWinter) {
      //Resizing menu with new dropmenu
      refMenu.current!.className =
        " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[440px] transition-all duration-1000";
      setHoverWinter(false);
    }
    if (!hoverWinter) {
      //Resizing menu with new dropmenu
      refMenu.current!.className =
        " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[620px] transition-all duration-1000";
      setHoverWinter(true);
    }
  };
  const showWinter = (): void => {
    setHoverWinter(true);
  };
  const hideWinter = (): void => {
    if (areWeMobileState) {
      //Timeout so the LINK TO in ActMenuCard Can trigger before its being hide
      setTimeout(() => {
        setHoverWinter(false);
      }, 100);
    } else {
      setHoverWinter(false);
    }
  };
  // END-------------------Winter Menu-----------------------------------***

  // ***-------------------Yourte Menu-----------------------------------***
  const [hoverYourte, setHoverYourte] = useState(false);
  const [activitiesYourte, setActivitiesYourte] = useState<activities[]>(yourteActivities);
  const [activitiesYourteEng, setActivitiesYourteEng] = useState<activities[]>(yourteActivities);
  const boxYourte = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoverYourte) {
      if (areWeMobileState) {
        ForceIosSummerMenuAnimation();
        ForceIosWinterMenuAnimation();
        boxYourte.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-[180px] ease-in-out";
      } else {
        boxYourte.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-96 left-0";
      }
    }
    if (!hoverYourte) {
      if (areWeMobileState) {
        boxYourte.current!.className =
          "relative visible duration-1000 opacity-0 z-0 h-0 ease-in-out";
      } else {
        boxYourte.current!.className =
          " relative invisible -left-96 duration-500 opacity-0 sm:-ml-96 ml-96 h-0";
      }
    }
  }, [hoverYourte]);

  const toggleYourte = (): void => {
    if (hoverYourte) {
      //Resizing menu with new dropmenu
      refMenu.current!.className =
        " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[440px] transition-all duration-1000";
      setHoverYourte(false);
    }
    if (!hoverYourte) {
      //Resizing menu with new dropmenu
      refMenu.current!.className =
        " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[620px] transition-all duration-1000";
      setHoverYourte(true);
    }
  };

  const showYourte = (): void => {
    setHoverYourte(true);
  };
  const hideYourte = (): void => {
    if (areWeMobileState) {
      //Timeout so the LINK TO in ActMenuCard Can trigger before its being hide
      setTimeout(() => {
        setHoverYourte(false);
      }, 100);
    } else {
      setHoverYourte(false);
    }
  };
  // END-------------------Yourte Menu-----------------------------------***

  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      const fetchedSummerActivities: activities[] = await getActivitiesCardsSummer();
      setActivitiesSummer(fetchedSummerActivities);
      const fetchedWinterActivities: activities[] = await getActivitiesCardsWinter();
      setActivitiesWinter(fetchedWinterActivities);
      const fetchedYourteActivities: activities[] = await getActivitiesCardsYourte();
      setActivitiesYourte(fetchedYourteActivities);
      const fetchedSummerActivitiesEng: activities[] = await getActivitiesCardsSummerEng();
      setActivitiesSummerEng(fetchedSummerActivitiesEng);
      const fetchedWinterActivitiesEng: activities[] = await getActivitiesCardsWinterEng();
      setActivitiesWinterEng(fetchedWinterActivitiesEng);
      const fetchedYourteActivitiesEng: activities[] = await getActivitiesCardsYourteEng();
      setActivitiesYourteEng(fetchedYourteActivitiesEng);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  // ***-------------------PATCH IOS needed function for menu animation-----------------------------------***
  const ForceIosSummerMenuAnimation = (): void => {
    setHoverSummer(false);
    boxSummer.current!.className = "relative visible duration-1000 opacity-0 z-0 h-0 ease-in-out";
  };

  const ForceIosWinterMenuAnimation = (): void => {
    setHoverWinter(false);
    boxWinter.current!.className = "relative visible duration-1000 opacity-0 z-0 h-0 ease-in-out";
  };

  const ForceIosYourteMenuAnimation = (): void => {
    setHoverYourte(false);
    boxYourte.current!.className = "relative visible duration-1000 opacity-0 z-0 h-0 ease-in-out";
  };
  // END-------------------PATCH IOS needed function for menu animation-----------------------------------***

  // ***-------------------signup modal-----------------------------------***

  const [modalSignupVisible, setModalSignupVisible] = useState(false);
  const [modalSigninVisible, setModalSigninVisible] = useState(false);

  const showSignupModal = () => {
    if (modalSignupVisible) {
      hideSignupModal();
    } else {
      if (modalSigninVisible) {
        hideSigninModal();
      }
      setModalSignupVisible(true);
      const divModalSignup = document.getElementById("modalSignup");
      if (divModalSignup) {
        divModalSignup!.className = "fixed z-30 border-8 border-robins-egg-blue-50 bg-opacity-50";
      }
    }
  };

  const hideSignupModal = () => {
    setModalSignupVisible(false);
  };
  const showSigninModal = () => {
    if (modalSigninVisible) {
      hideSigninModal();
    } else {
      if (modalSignupVisible) {
        hideSignupModal();
      }
      setModalSigninVisible(true);
      const divModalSignin = document.getElementById("modalSignin");
      if (divModalSignin) {
        divModalSignin!.className = "fixed z-30 border-8 border-robins-egg-blue-50 bg-opacity-50";
      }
    }
  };

  const hideSigninModal = () => {
    setModalSigninVisible(false);
  };

  // END-------------------signup modal-----------------------------------***

  // .----------------.  .----------------.  .-----------------. .----------------.  .----------------.  .----------------.
  // | .--------------. || .--------------. || .--------------. || .--------------. || .--------------. || .--------------. |
  // | |  _______     | || |  _________   | || | ____  _____  | || |  ________    | || |  _________   | || |  _______     | |
  // | | |_   __ \    | || | |_   ___  |  | || ||_   \|_   _| | || | |_   ___ `.  | || | |_   ___  |  | || | |_   __ \    | |
  // | |   | |__) |   | || |   | |_  \_|  | || |  |   \ | |   | || |   | |   `. \ | || |   | |_  \_|  | || |   | |__) |   | |
  // | |   |  __ /    | || |   |  _|  _   | || |  | |\ \| |   | || |   | |    | | | || |   |  _|  _   | || |   |  __ /    | |
  // | |  _| |  \ \_  | || |  _| |___/ |  | || | _| |_\   |_  | || |  _| |___.' / | || |  _| |___/ |  | || |  _| |  \ \_  | |
  // | | |____| |___| | || | |_________|  | || ||_____|\____| | || | |________.'  | || | |_________|  | || | |____| |___| | |
  // | |              | || |              | || |              | || |              | || |              | || |              | |
  // | '--------------' || '--------------' || '--------------' || '--------------' || '--------------' || '--------------' |
  //  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'
  return (
    <div className="flex flex-col w-full" id="top">
      {modalSignupVisible ? (
        <div id="modalSignup" className="flex justify-center z-30 border-robins-egg-blue-50">
          <Signup showModal={modalSignupVisible} handleClose={hideSignupModal} />
        </div>
      ) : (
        <div></div>
      )}

      {modalSigninVisible ? (
        <div id="modalSignin " className="flex justify-center z-30 border-robins-egg-blue-50">
          <Login showModal={modalSigninVisible} handleClose={hideSigninModal} />
        </div>
      ) : (
        <div></div>
      )}
      <div className="z-20">
        <div className="flex sm:flex-row flex-col justify-between">
          <div className="flex m-1 items-center justify-center">
            <Link to="/" className="flex justify-center h-16">
              <img className="" id="logoTopMenu" src={logoAQS} alt="Aventure Quatre Saisons" />
            </Link>
          </div>

          {/* --------------------MENU hide on Mobile only----------------------------------------------- */}
          {areWeMobileState ? (
            <div onClick={toggleMenu} className="flex items-center justify-center">
              <div className=" border-[1px] border-aqs-blue-700 p-2 hover:bg-aqs-blue-200">
                MENU
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {/* END-----------------MENU hide on Mobile only------------------------------------------------ */}

          {/* --------------------Booking clic cause Close Menu on booking click only mobil--------------- */}
          <div className="flex sm:flex-row flex-col " ref={refMenu}>
            {/* {areWeMobileState ? (
              <div id="mobile 3" onClick={toggleMenu}>
                <div
                  id="mobile 3.5"
                  className="flex items-center cursor-pointer hover:bg-aqs-blue-300 duration-1000 m-1 sm:h-24"
                  onClick={hideShowBookingBox}>
                  <TopMenuItem
                    icon={faCalendar}
                    name={language === "Eng" ? "Book Now" : "Réserver"}
                  />
                </div>
              </div>
            ) : (
              <div id="mobile 5" onClick={hideShowBookingBox} className=" flex items-center">
                <TopMenuItem
                  icon={faCalendar}
                  name={language === "Eng" ? "Book Now" : "Réserver"}
                />
              </div>
            )} */}
            {/* END-----------------Booking clic cause Close Menu on booking click only mobil-------------- */}

            {/* --------------------Close Menu on TopItemClick only on mobile-------------------------------- */}
            {areWeMobileState ? (
              <div className="flex flex-col sm:flex-row" id="2 mobile">
                <div id="mobile 3" onClick={toggleMenu}>
                  <div
                    id="mobile 3.5"
                    className="flex items-center cursor-pointer hover:bg-aqs-blue-300 duration-1000 m-1"
                    onClick={hideShowBookingBox}>
                    <TopMenuItem
                      icon={faCalendar}
                      name={language === "Eng" ? "Book Now" : "Réserver"}
                    />
                  </div>
                </div>
                <div id="1 mobile" className="w-full overflow-hidden">
                  <div id="summer" onClick={toggleSummer} onBlur={hideSummer}>
                    <div>
                      <Link
                        to={
                          language === "Eng"
                            ? "/en/laurentides/riviere-rouge/ete"
                            : "/fr/laurentides/riviere-rouge/ete"
                        }>
                        <TopMenuItem icon={faSun} name={language === "Eng" ? "Summer" : "Été"} />
                      </Link>
                    </div>
                  </div>
                  <div className="relative invisible mx-64 opacity-0 h-0" ref={boxSummer}>
                    <div onClick={toggleMenu}>
                      <MenuDropDown
                        activities={language === "Eng" ? activitiesSummerEng : activitiesSummer}
                        mobileView={true}></MenuDropDown>
                    </div>
                  </div>
                </div>

                <div className="w-full overflow-hidden">
                  <div id="winter" onClick={toggleWinter} onBlur={hideWinter}>
                    <div>
                      <Link
                        to={
                          language === "Eng"
                            ? "/en/laurentides/riviere-rouge/hiver"
                            : "/fr/laurentides/riviere-rouge/hiver"
                        }>
                        <TopMenuItem
                          icon={faSnowflake}
                          name={language === "Eng" ? "Winter" : "Hiver"}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="relative invisible mx-64 opacity-0 h-0" ref={boxWinter}>
                    <div onClick={toggleMenu} className="">
                      <MenuDropDown
                        activities={language === "Eng" ? activitiesWinterEng : activitiesWinter}
                        mobileView={true}></MenuDropDown>
                    </div>
                  </div>
                </div>

                <div className="w-full overflow-hidden">
                  <div id="yourte" onClick={toggleYourte} onBlur={hideYourte}>
                    <div>
                      <Link
                        to={
                          language === "Eng"
                            ? "/en/laurentides/riviere-rouge/yourte"
                            : "/fr/laurentides/riviere-rouge/yourte"
                        }>
                        <TopMenuItem icon={faHome} name={language === "Eng" ? "Yurt" : "Yourte"} />
                      </Link>
                    </div>
                  </div>
                  <div className="relative invisible mx-64 opacity-0 h-0" ref={boxYourte}>
                    <div onClick={toggleMenu} className="">
                      <MenuDropDown
                        activities={language === "Eng" ? activitiesYourteEng : activitiesYourte}
                        mobileView={true}></MenuDropDown>
                    </div>
                  </div>
                </div>
                <div>
                  <Link
                    onClick={toggleMenu}
                    to={
                      language === "Eng"
                        ? "/en/laurentides/riviere-rouge/carte"
                        : "/fr/laurentides/riviere-rouge/carte"
                    }>
                    <TopMenuItem icon={faMap} name={language === "Eng" ? "Map" : "Cartes"} />
                  </Link>
                </div>
                <div>
                  <Link
                    onClick={toggleMenu}
                    to={
                      language === "Eng"
                        ? "/en/laurentides/riviere-rouge/ventedeyourte"
                        : "/fr/laurentides/riviere-rouge/ventedeyourte"
                    }>
                    <TopMenuItem
                      icon={faHome}
                      name={language === "Eng" ? "Yurt(Sales)" : "Yourte(achat)"}
                    />
                  </Link>
                </div>
                <div>
                  <Link
                    onClick={toggleMenu}
                    to={
                      language === "Eng"
                        ? "/en/laurentides/riviere-rouge/contact"
                        : "/fr/laurentides/riviere-rouge/contact"
                    }>
                    <TopMenuItem icon={faPhone} name="Contact" />
                  </Link>
                </div>
              </div>
            ) : (
              <div id="1 pc" className="flex items-center">
                <div id="mobile 5" onClick={hideShowBookingBox} className=" flex items-center">
                  <TopMenuItem
                    icon={faCalendar}
                    name={language === "Eng" ? "Book Now" : "Réserver"}
                  />
                </div>
                <div
                  id="summer"
                  onMouseEnter={showSummer}
                  onMouseLeave={hideSummer}
                  onClick={hideSummer}>
                  <div>
                    <Link
                      to={
                        language === "Eng"
                          ? "/en/laurentides/riviere-rouge/ete"
                          : "/fr/laurentides/riviere-rouge/ete"
                      }>
                      <TopMenuItem icon={faSun} name={language === "Eng" ? "Summer" : "Été"} />
                    </Link>
                  </div>
                </div>
                <div
                  id="winter"
                  onMouseEnter={showWinter}
                  onMouseLeave={hideWinter}
                  onClick={hideWinter}>
                  <div>
                    <Link
                      to={
                        language === "Eng"
                          ? "/en/laurentides/riviere-rouge/hiver"
                          : "/fr/laurentides/riviere-rouge/hiver"
                      }>
                      <TopMenuItem
                        icon={faSnowflake}
                        name={language === "Eng" ? "Winter" : "Hiver"}
                      />
                    </Link>
                  </div>
                </div>

                <div
                  id="yourte"
                  onMouseEnter={showYourte}
                  onMouseLeave={hideYourte}
                  onClick={hideYourte}>
                  <div>
                    <Link
                      to={
                        language === "Eng"
                          ? "/en/laurentides/riviere-rouge/yourte"
                          : "/fr/laurentides/riviere-rouge/yourte"
                      }>
                      <TopMenuItem icon={faHome} name={language === "Eng" ? "Yurt" : "Yourte"} />
                    </Link>
                  </div>
                </div>
                <div>
                  <Link
                    to={
                      language === "Eng"
                        ? "/en/laurentides/riviere-rouge/carte"
                        : "/fr/laurentides/riviere-rouge/carte"
                    }>
                    <TopMenuItem icon={faMap} name={language === "Eng" ? "Map" : "carte"} />
                  </Link>
                </div>
                <div className="w-52 text-left text">
                  <Link
                    to={
                      language === "Eng"
                        ? "/en/laurentides/riviere-rouge/ventedeyourte"
                        : "/fr/laurentides/riviere-rouge/ventedeyourte"
                    }>
                    <TopMenuItem
                      icon={faHome}
                      name={language === "Eng" ? "Yurt(Sales)" : "Yourte(achat)"}
                    />
                  </Link>
                </div>
                <div>
                  <Link
                    to={
                      language === "Eng"
                        ? "/en/laurentides/riviere-rouge/contact"
                        : "/fr/laurentides/riviere-rouge/contact"
                    }>
                    <TopMenuItem icon={faPhone} name="Contact" />
                  </Link>
                </div>
              </div>
            )}
          </div>
          {/* END-----------------Close Menu on TopItemClick only on mobile------------------------------- */}

          {/* --------------------FaceBook - Twitter - Youtube-------------------------------------------- */}
          <div className="flex flex-row items-center justify-center ">
            <div className="p-2 hover:scale-[110%] duration-1000 min-w-[50px]">
              <a
                href="https://www.facebook.com/aventurequatresaisons/"
                target="_blank"
                rel="noreferrer">
                <img className="w-[100%%] h-[100%]" src={facebook} alt="Notre page Facebook" />
              </a>
            </div>
            <div className="p-2 hover:scale-[110%] duration-1000 min-w-[50px]">
              <a href="https://twitter.com/aventure4saison/" target="_blank" rel="noreferrer">
                <img className="w-[100%%] h-[100%]" src={twitter} alt="Notre page Twitter" />
              </a>
            </div>
            <div className="p-2 hover:scale-[110%] duration-1000 min-w-[50px]">
              <a
                href="https://www.instagram.com/aventurequatresaisons/"
                target="_blank"
                rel="noreferrer">
                <img className="w-[100%%] h-[100%]" src={instagram} alt="Notre page instagram" />
              </a>
            </div>
          </div>
          {/* END----------------FaceBook - Twitter - Youtube-------------------------------------------- */}

          {/* ***----------------Login - Logout - username-------------------------------------------- */}
          {isLoggedContextState ? (
            <div className="flex justify-center z-50 flex-col align-middle p-1">
              <div>
                <div className="flex flex-col rounded-xl h-10 justify-center ">
                  <div className="flex justify-center sm:border-2 border-0 w-full p-1">
                    <div>
                      <FontAwesomeIcon icon={faUser} size="lg" />
                    </div>
                    <div className="">
                      <p>{currentUser.username}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex justify-center w-full p-1">
                  <Logout />
                </div>
                <div
                  onClick={toggleLanguage}
                  className="flex h-10 w-20 justify-center border-2 p-2 mb-1 ml-3 hover:bg-aqs-blue-400/50 rounded-xl hover:cursor-pointer">
                  FR/EN
                </div>
                {currentUser.admin === 1 ? (
                  <div className="flex justify-center">
                    <div className="flex justify-center border-2 rounded-xl sm:w-full w-32 hover:bg-aqs-blue-400/50 hover:cursor-pointer p-1">
                      <Link to="/fr/laurentides/riviere-rouge/merchant">MERCHANT</Link>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ) : (
            //
            <div className="flex justify-center items-center z-50">
              <div className="flex">
                <div className="flex " onClick={showSigninModal}>
                  <TopMenuItem
                    icon={faSignInAlt}
                    name={language === "Eng" ? "Signin" : "Connection"}
                    size="text-xs"
                  />
                </div>
              </div>
              <div>
                <div onClick={showSignupModal}>
                  <TopMenuItem
                    icon={faAward}
                    name={language === "Eng" ? "Signup" : "Inscription"}
                    size="text-xs"
                  />
                </div>
              </div>

              <div
                onClick={toggleLanguage}
                className="flex h-10 w-20 justify-center border-2 p-2 mb-1 ml-3 hover:bg-aqs-blue-400/50 rounded-xl hover:cursor-pointer">
                <Link to="/en/laurentides/riviere-rouge/canot">FR/EN</Link>
              </div>
            </div>
          )}

          {/* END--------------------Login - Logout - username-------------------------------------------- */}
        </div>
      </div>
      {/* ----------------------------------MenuDropDown For PC view Only-------------------------------- */}
      {areWeMobileState ? (
        <div></div>
      ) : (
        <div>
          <div
            className="relative invisible opacity-0 h-0"
            onMouseEnter={showSummer}
            onMouseLeave={hideSummer}
            onClick={hideSummer}
            ref={boxSummer}>
            <MenuDropDown
              activities={
                language === "Eng" ? activitiesSummerEng : activitiesSummer
              }></MenuDropDown>
          </div>
          <div
            className="relative invisible opacity-0 h-0"
            onMouseEnter={showWinter}
            onMouseLeave={hideWinter}
            onClick={hideWinter}
            ref={boxWinter}>
            <MenuDropDown
              activities={
                language === "Eng" ? activitiesWinterEng : activitiesWinter
              }></MenuDropDown>
          </div>
          <div
            className="relative invisible opacity-0 h-0"
            onMouseEnter={showYourte}
            onMouseLeave={hideYourte}
            onClick={hideYourte}
            ref={boxYourte}>
            <MenuDropDown
              activities={
                language === "Eng" ? activitiesYourteEng : activitiesYourte
              }></MenuDropDown>
          </div>
        </div>
      )}
      {/* END-------------------------------MenuDropDown For PC view Only-------------------------------- */}

      {/* ***-------------------------------hidden english for React-Snape------------------------------- */}
      {/* <div className="opacity-0 h-0 z-0 overflow-hidden">
        <Link to="/en/laurentides/riviere-rouge/ete">Ete</Link>
        <Link to="/en/laurentides/riviere-rouge/hiver">Hiver</Link>
        <Link to="/en/laurentides/riviere-rouge/yourte">yourte</Link>
        <Link to="/en/laurentides/riviere-rouge/canot">canot</Link>
        <Link to="/en/laurentides/riviere-rouge/kayak/">kayak</Link>
        <Link to="/en/laurentides/riviere-rouge/standup-paddleboard">canot</Link>
        <Link to="/en/laurentides/riviere-rouge/canot-camping">canot-camping</Link>
        <Link to="/en/laurentides/riviere-rouge/traineauachiens">traineauachiens</Link>
        <Link to="/en/laurentides/riviere-rouge/traineauachiens-vip">traineauachiens-vip</Link>
        <Link to="/en/laurentides/riviere-rouge/randonnee-tractee">randonnee-tractee</Link>
        <Link to="/en/laurentides/riviere-rouge/nuiteeenyourte">nuiteeenyourte</Link>
        <Link to="/en/laurentides/riviere-rouge/2nuiteeenyourte">2nuiteeenyourte</Link>
        <Link to="/en/laurentides/riviere-rouge/3nuiteeenyourte">3nuiteeenyourte</Link>
        <Link to="/en/laurentides/riviere-rouge/ventedeyourte">ventedeyourte</Link>
        <Link to="/en/laurentides/riviere-rouge/carte">carte</Link>
        <Link to="/en/laurentides/riviere-rouge/contact">contact</Link>
      </div> */}
      {/* END-------------------------------hidden english for React-Snape-------------------------------- */}
    </div>
  );
};

export default TopMenu;
