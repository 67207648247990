/* eslint-disable no-unused-vars */
export const parseMsToDateString = (dateMs: number): string => {
  const date = new Date(dateMs);

  let day: string = "";
  let month: string = "";

  if (date.getDate() < 10) {
    day = "0" + date.getDate();
  } else {
    day = date.getDate().toString();
  }

  if (date.getMonth() < 10) {
    month = "0" + (date.getMonth() + 1);
  } else {
    month = date.getMonth().toString();
  }

  // return ` ${day}/${month}/${date.getFullYear()}`;
  return date.toDateString() + " at " + date.getHours() + "h" + date.getMinutes();
};

export async function addDaysToDate(date: Date, deltaDate: number): Promise<Date> {
  date.setDate(date.getDate() + deltaDate);
  const newDate = date;

  return newDate;
}

export function addDaysToDateSynchrone(date: Date, deltaDate: number) {
  date.setDate(date.getDate() + deltaDate);
  const newDate = date;

  return newDate;
}

export function getDateAsDayValue(date: Date) {
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear()
  };
}

export async function dateToString(date: Date): Promise<string> {
  const newDay = (date.getDate() < 10 ? "0" : "") + date.getDate();
  const newMonth = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1).toString();
  const newYear = date.getFullYear();

  return newYear + "-" + newMonth + "-" + newDay;
}

export function dateToStringSynchrone(date: Date) {
  const newDay = (date.getDate() < 10 ? "0" : "") + date.getDate();
  const newMonth = (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1).toString();
  const newYear = date.getFullYear();

  return newYear + "-" + newMonth + "-" + newDay;
}

export async function stringToDate(date: string): Promise<Date> {
  const splitDate = date.split("-");

  return new Date(parseInt(splitDate[0]), parseInt(splitDate[1]) - 1, parseInt(splitDate[2]));
}

export async function getNextDateStringFormat(date: string, deltaDate: number): Promise<string> {
  const dateNew = stringToDate(date);
  const nextdate = addDaysToDate(await dateNew, deltaDate);
  return dateToString(await nextdate);
}
