/* eslint-disable react/jsx-key */
// eslint-disable-next-line no-use-before-define
import React, { useContext, useEffect, useState } from "react";
import { getFullResa } from "../../services/bookingService";
import Resa from "./Resa";
import { reservation } from "../../types/reservationTypes";
import { v4 as uuidv4 } from "uuid";
import LeftMenu from "./LeftMenu";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";
import NotFound from "../helper/NotFound";

const ResaList: React.FC = () => {
  const [resas, setResas] = useState<reservation[]>([]);

  const { currentUser, isLoggedContextState } = useContext(AuthContext) as UserContextType;

  useEffect(() => {
    getResa();
  }, []);

  const getResa = async () => {
    try {
      const fetchedResa: reservation[] = await getFullResa();
      setResas(fetchedResa);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  return (
    <div className=" w-full">
      {isLoggedContextState && currentUser.admin === 1 ? (
        <div
          id="wall"
          className="flex mx-3 mr-1 pr-3 w-full scrollbar-thin scrollbar-thumb-white scrollbar-track-gray overflow-y-scroll">
          <div className="w-[10%]">
            <LeftMenu />
          </div>
          <div className="w-[90%]">
            {resas.map((reservation: reservation) => (
              <Resa key={uuidv4()} reservation={reservation} setResas={setResas} resas={resas} />
            ))}
          </div>
        </div>
      ) : (
        <div>
          <NotFound />
        </div>
      )}
    </div>
  );
};

export default ResaList;
