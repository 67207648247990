import React, { useContext, useState } from "react";
import { TalkToUser } from "../helper/TalkToUser";
import { loginUser, userValidation } from "../../types/userType";
import { login } from "../../services/userService";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";
import signUp from "../../images/signinCrop.jpg";
import ValidEntry from "./ValidEntry";
import { emptyUserLogin } from "../helper/defaultDataContent";

type IProps = {
  handleClose: () => void;
  showModal: boolean;
};

const Login: React.FC<IProps> = ({ handleClose }) => {
  const { updateUser } = useContext(AuthContext) as UserContextType;

  const [newUser, setUser] = useState<loginUser>(emptyUserLogin);

  const [userLoginValidation, setUserLoginValidation] = useState<userValidation | any>({
    username: false, //False only used field is username and password for login all the rest at true
    firstName: true,
    lastName: true,
    email: true,
    telephone: true,
    password: false
  });

  const checkAllForm = (): boolean => {
    const allRegExpOk = Object.keys(userLoginValidation).every((k) => userLoginValidation[k]); //Validate if all keys are true

    let formAllGood = true;
    if (newUser.username === undefined || newUser.password === undefined) {
      formAllGood = false;
    }
    if (!allRegExpOk) {
      formAllGood = false;
    }

    return formAllGood;
  };

  // eslint-disable-next-line no-unused-vars
  const setNewUser = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const userTmp = { ...newUser, [event.target.id]: event.target.value };
    let validationUserTmp = { ...userLoginValidation, [event.target.id]: true };

    if (event.target.validity.patternMismatch) {
      validationUserTmp = { ...userLoginValidation, [event.target.id]: false };
    }

    if (event.target.validity.valueMissing) {
      validationUserTmp = { ...userLoginValidation, [event.target.id]: false };
    }

    setUser(userTmp);
    setUserLoginValidation(validationUserTmp);
  };

  const sendSub = async () => {
    if (checkAllForm()) {
      try {
        const loggedUser = await login(newUser.username, newUser.password);
        updateUser(loggedUser);
        handleClose();
      } catch (error) {
        TalkToUser(error as string);
      }
    } else {
      TalkToUser("Svp aucun champs vide...Entrez vos informations de connection.");
    }
  };

  return (
    <div className="absolute top-[10%] opacity-100 z-40 w-[100%] sm:w-[500px]">
      <div className="bg-gradient-to-b to-aqs-blue-500 from-aqs-green-50 border-4 text-xl">
        <div className=" m-4 p-3 flex flex-row justify-between">
          <div
            onClick={handleClose}
            className=" hover:bg-aqs-green-700/50 rounded-md cursor-pointer p-2">
            <p>X</p>
          </div>
          <div className="text-4xl">
            <p>SIGN IN</p>
          </div>
          <div></div>
        </div>
        <div className="m-3">
          <img id="logoTopMenu" src={signUp} alt="Nos chiens de traineau au travail!" />
        </div>
        <form className="">
          <div className="border-2 m-4 p-3">
            <div className="">
              <div className="p-2">
                <label className="">Username:</label>
              </div>
              <div>
                <input
                  className="border-2 border-aqs-green-100 rounded-md p-1 w-full invalid:border-2 invalid:border-red/70 invalid:bg-red/10 valid:border-[1px] valid:border-green valid:bg-green/10"
                  id={"username"}
                  onChange={setNewUser}
                  type="text"
                  name="username"
                  placeholder={"Username"}
                  value={newUser.username}
                  required
                />
                <ValidEntry valid={userLoginValidation.username} />
              </div>
            </div>
            <div className="">
              <div className="p-2">
                <label className="">Password:</label>
              </div>
              <div>
                <input
                  className="border-2 border-aqs-green-100 rounded-md p-1 w-full invalid:border-2 invalid:border-red/70 invalid:bg-red/10 valid:border-[1px] valid:border-green valid:bg-green/10"
                  id={"password"}
                  onChange={setNewUser}
                  type="password"
                  name="password"
                  placeholder={"Password"}
                  value={newUser.password}
                  required
                />
                <ValidEntry valid={userLoginValidation.password} />
              </div>
            </div>
          </div>
          <div className="flex justify-center ">
            <div
              onClick={sendSub}
              className="border-2 flex justify-center m-4 p-3 bg-aqs-green-300 hover:bg-aqs-green-100 hover:cursor-pointer rounded-lg w-[25%]">
              <p>Submit</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
