import React, { useContext, useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import { getPackagesSup } from "../../services/packageService";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { supPackages } from "../helper/defaultDataContent";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
  languageUrl?: string;
};

const Sup: React.FC<IProps> = ({ languageUrl = "", hideShowBookingBox }) => {
  const { language } = useContext(AuthContext) as UserContextType;
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(supPackages);
  const title = "Sup";

  // ***-------------------Retrieving Activity-----------------------------------***

  const { setNewLanguage } = useContext(AuthContext) as UserContextType;
  useEffect(() => {
    if (languageUrl === "Eng") {
      setNewLanguage("Eng");
    } else {
      setNewLanguage("");
    }
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesSup();
      setPackagesCanoeState(fetchedPackagesCanoe);
    } catch (err) {
      throw new Error(err as string);
    }
  };
  // END-------------------Retrieving Activity-----------------------------------***
  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng"
              ? "StandUp Paddleboard rental Riviere-Rouge"
              : "Location PaddleBoard SUP Rivière-Rouge"
          }
        />
        {language === "Eng"
          ? "Rental SUP(StandUp Paddleboard) - Rivière-Rouge"
          : "Location SUP(StandUp Paddleboard) - Rivière-Rouge"}
        <meta
          name="description"
          content={
            language === "Eng"
              ? "Rental SUP(StandUp Paddleboard) - Rivière-Rouge"
              : "Location SUP(StandUp Paddleboard) - Rivière-Rouge"
          }
        />
        <title>
          {language === "Eng"
            ? "StandUp Paddleboard rental Riviere-Rouge"
            : "Location PaddleBoard SUP Rivière-Rouge"}
        </title>
      </Helmet>
      <div className="z-20 w-full">
        <h1 className="hidden">
          {language === "Eng"
            ? "PaddleBoard - Rivière-Rouge - Mont-Tremblant"
            : "PaddleBoard - Rivière-Rouge - Mont-Tremblant"}
        </h1>
        <MainContentPage
          title={title}
          packagesCanoe={packagesCanoeState}
          hideShowBookingBox={hideShowBookingBox}></MainContentPage>
      </div>
    </div>
  );
};

export default Sup;
