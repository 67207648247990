/* eslint-disable react/jsx-key */
// eslint-disable-next-line no-use-before-define
import React, { useContext, useEffect, useState } from "react";
import { getResaDate } from "../../services/bookingService";
import { reservation } from "../../types/reservationTypes";
import { v4 as uuidv4 } from "uuid";
import Master from "./Master";
// import DatePicker, { DayValue } from 'react-modern-calendar-datepicker';
// import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, { DayValue } from "@hassanmojab/react-modern-calendar-datepicker";
import { dateToString } from "../helper/dateParsing";
import { inventory } from "../../types/inventoryType";
import { postInventoryForDate } from "../../services/inventoryService";
import LeftMenu from "./LeftMenu";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";
import NotFound from "../helper/NotFound";

const MasterList: React.FC = () => {
  const [inventory, setinventory] = useState<inventory[]>([]);
  const [resas, setResas] = useState<reservation[]>([]);
  const { currentUser, isLoggedContextState } = useContext(AuthContext) as UserContextType;
  const [total, setTotal] = useState<reservation>({
    ID: "string",
    activite: "----------------->",
    confDate: "string",
    startDate: "string",
    duree: 1,
    departureTime: 0,
    prename: "TOTAUX",
    name: "DÉPART",
    nbPaxAd: 0,
    nbPaxEnf: 0,
    nbCanoe: 0,
    yourte: "string",
    nbKayak: 0,
    nbSUP: 0,
    courriel: "string",
    tel: "string",
    modePaiement: "string",
    enterprise01: "",
    enterprise: "",
    specialCode: "none",
    pricing: 0,
    expiryMonth: 0,
    expiryYear: 0
  });

  const [resas9h, setResas9h] = useState<reservation[]>([]);
  const [resas10h, setResas10h] = useState<reservation[]>([]);
  const [resas11h, setResas11h] = useState<reservation[]>([]);
  const [resas14h, setResas14h] = useState<reservation[]>([]);

  const [total9h, setTotal9h] = useState<reservation>({
    ID: "string",
    activite: "----------------->",
    confDate: "string",
    startDate: "string",
    duree: 1,
    departureTime: 0,
    prename: "TOTAUX",
    name: "DÉPART",
    nbPaxAd: 0,
    nbPaxEnf: 0,
    nbCanoe: 0,
    yourte: "string",
    nbKayak: 0,
    nbSUP: 0,
    courriel: "string",
    tel: "string",
    modePaiement: "string",
    enterprise01: "",
    enterprise: "",
    specialCode: "none",
    pricing: 0,
    expiryMonth: 0,
    expiryYear: 0
  });

  const [total10h, setTotal10h] = useState<reservation>({
    ID: "string",
    activite: "----------------->",
    confDate: "string",
    startDate: "string",
    duree: 1,
    departureTime: 0,
    prename: "TOTAUX",
    name: "DÉPART",
    nbPaxAd: 0,
    nbPaxEnf: 0,
    nbCanoe: 0,
    yourte: "string",
    nbKayak: 0,
    nbSUP: 0,
    courriel: "string",
    tel: "string",
    modePaiement: "string",
    enterprise01: "",
    enterprise: "",
    specialCode: "none",
    pricing: 0,
    expiryMonth: 0,
    expiryYear: 0
  });

  const [total11h, setTotal11h] = useState<reservation>({
    ID: "string",
    activite: "----------------->",
    confDate: "string",
    startDate: "string",
    duree: 1,
    departureTime: 0,
    prename: "TOTAUX",
    name: "DÉPART",
    nbPaxAd: 0,
    nbPaxEnf: 0,
    nbCanoe: 0,
    yourte: "string",
    nbKayak: 0,
    nbSUP: 0,
    courriel: "string",
    tel: "string",
    modePaiement: "string",
    enterprise01: "",
    enterprise: "",
    specialCode: "none",
    pricing: 0,
    expiryMonth: 0,
    expiryYear: 0
  });

  const [total14h, setTotal14h] = useState<reservation>({
    ID: "string",
    activite: "----------------->",
    confDate: "string",
    startDate: "string",
    duree: 1,
    departureTime: 0,
    prename: "TOTAUX",
    name: "DÉPART",
    nbPaxAd: 0,
    nbPaxEnf: 0,
    nbCanoe: 0,
    yourte: "string",
    nbKayak: 0,
    nbSUP: 0,
    courriel: "string",
    tel: "string",
    modePaiement: "string",
    enterprise01: "",
    enterprise: "",
    specialCode: "none",
    pricing: 0,
    expiryMonth: 0,
    expiryYear: 0
  });

  const [day, setDay] = React.useState<DayValue>(null);

  useEffect(() => {
    getResa(day);
    getJournee(day);
  }, []);

  useEffect(() => {}, [inventory]);

  useEffect(() => {
    getResa(day);
    getJournee(day);

    setTotal9h({
      ID: "string",
      activite: "----------------->",
      confDate: "string",
      startDate: "string",
      duree: 1,
      departureTime: 0,
      prename: "TOTAUX",
      name: "DÉPART",
      nbPaxAd: 0,
      nbPaxEnf: 0,
      nbCanoe: 0,
      yourte: "string",
      nbKayak: 0,
      nbSUP: 0,
      courriel: "string",
      tel: "string",
      modePaiement: "string",
      enterprise01: "",
      enterprise: "",
      specialCode: "none",
      pricing: 0,
      expiryMonth: 0,
      expiryYear: 0
    });
    setTotal10h({
      ID: "string",
      activite: "----------------->",
      confDate: "string",
      startDate: "string",
      duree: 1,
      departureTime: 0,
      prename: "TOTAUX",
      name: "DÉPART",
      nbPaxAd: 0,
      nbPaxEnf: 0,
      nbCanoe: 0,
      yourte: "string",
      nbKayak: 0,
      nbSUP: 0,
      courriel: "string",
      tel: "string",
      modePaiement: "string",
      enterprise01: "",
      enterprise: "",
      specialCode: "none",
      pricing: 0,
      expiryMonth: 0,
      expiryYear: 0
    });
    setTotal11h({
      ID: "string",
      activite: "----------------->",
      confDate: "string",
      startDate: "string",
      duree: 1,
      departureTime: 0,
      prename: "TOTAUX",
      name: "DÉPART",
      nbPaxAd: 0,
      nbPaxEnf: 0,
      nbCanoe: 0,
      yourte: "string",
      nbKayak: 0,
      nbSUP: 0,
      courriel: "string",
      tel: "string",
      modePaiement: "string",
      enterprise01: "",
      enterprise: "",
      specialCode: "none",
      pricing: 0,
      expiryMonth: 0,
      expiryYear: 0
    });

    setTotal14h({
      ID: "string",
      activite: "----------------->",
      confDate: "string",
      startDate: "string",
      duree: 1,
      departureTime: 0,
      prename: "TOTAUX",
      name: "DÉPART",
      nbPaxAd: 0,
      nbPaxEnf: 0,
      nbCanoe: 0,
      yourte: "string",
      nbKayak: 0,
      nbSUP: 0,
      courriel: "string",
      tel: "string",
      modePaiement: "string",
      enterprise01: "",
      enterprise: "",
      specialCode: "none",
      pricing: 0,
      expiryMonth: 0,
      expiryYear: 0
    });

    setTotal({
      ID: "string",
      activite: "----------------->",
      confDate: "string",
      startDate: "string",
      duree: 1,
      departureTime: 0,
      prename: "TOTAUX",
      name: "DÉPART",
      nbPaxAd: 0,
      nbPaxEnf: 0,
      nbCanoe: 0,
      yourte: "string",
      nbKayak: 0,
      nbSUP: 0,
      courriel: "string",
      tel: "string",
      modePaiement: "string",
      enterprise01: "",
      enterprise: "",
      specialCode: "none",
      pricing: 0,
      expiryMonth: 0,
      expiryYear: 0
    });
  }, [day]);

  useEffect(() => {
    triPardepartureTime();
  }, [resas]);

  // eslint-disable-next-line no-unused-vars
  const getResa = async (pday: DayValue) => {
    const dayDate = new Date(day?.year as number, (day?.month as number) - 1, day?.day, 0, 0, 0, 0);
    // const dayDate = `${pday?.day}%2F${pday?.month}%2F${pday?.year}`;
    const dayString = await dateToString(dayDate);
    try {
      const fetchedResa: reservation[] = await getResaDate(dayString);

      setResas([...fetchedResa]);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getJournee = async (pday: DayValue) => {
    const dayDate = new Date(day?.year as number, (day?.month as number) - 1, day?.day, 0, 0, 0, 0);
    const dayString = await dateToString(dayDate);
    try {
      const fetchedJournee: inventory[] = await postInventoryForDate(dayString);
      setinventory(fetchedJournee);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  const triPardepartureTime = async () => {
    const resa9h: reservation[] = [];
    const resa10h: reservation[] = [];
    const resa11h: reservation[] = [];
    const resa14h: reservation[] = [];

    resas.forEach((resa) => {
      total.nbPaxAd = total.nbPaxAd + resa.nbPaxAd;
      total.nbPaxEnf = total.nbPaxEnf + resa.nbPaxEnf;
      total.nbCanoe = total.nbCanoe + resa.nbCanoe;
      total.nbKayak = total.nbKayak + resa.nbKayak;
      total.nbSUP = total.nbSUP + resa.nbSUP;
      total.pricing = total.pricing + resa.pricing;
      switch (resa.departureTime) {
        case 9:
          resa9h.push(resa);
          total9h.nbPaxAd = total9h.nbPaxAd + resa.nbPaxAd;
          total9h.nbPaxEnf = total9h.nbPaxEnf + resa.nbPaxEnf;
          total9h.nbCanoe = total9h.nbCanoe + resa.nbCanoe;
          total9h.nbKayak = total9h.nbKayak + resa.nbKayak;
          total9h.nbSUP = total9h.nbSUP + resa.nbSUP;
          total9h.pricing = total9h.pricing + resa.pricing;
          break;
        case 10:
          resa10h.push(resa);
          total10h.nbPaxAd = total10h.nbPaxAd + resa.nbPaxAd;
          total10h.nbPaxEnf = total10h.nbPaxEnf + resa.nbPaxEnf;
          total10h.nbCanoe = total10h.nbCanoe + resa.nbCanoe;
          total10h.nbKayak = total10h.nbKayak + resa.nbKayak;
          total10h.nbSUP = total10h.nbSUP + resa.nbSUP;
          total10h.pricing = total10h.pricing + resa.pricing;
          break;
        case 11:
          resa11h.push(resa);
          total11h.nbPaxAd = total11h.nbPaxAd + resa.nbPaxAd;
          total11h.nbPaxEnf = total11h.nbPaxEnf + resa.nbPaxEnf;
          total11h.nbCanoe = total11h.nbCanoe + resa.nbCanoe;
          total11h.nbKayak = total11h.nbKayak + resa.nbKayak;
          total11h.nbSUP = total11h.nbSUP + resa.nbSUP;
          total11h.pricing = total11h.pricing + resa.pricing;
          break;
        case 14:
          resa14h.push(resa);
          total14h.nbPaxAd = total14h.nbPaxAd + resa.nbPaxAd;
          total14h.nbPaxEnf = total14h.nbPaxEnf + resa.nbPaxEnf;
          total14h.nbCanoe = total14h.nbCanoe + resa.nbCanoe;
          total14h.nbKayak = total14h.nbKayak + resa.nbKayak;
          total14h.nbSUP = total14h.nbSUP + resa.nbSUP;
          total14h.pricing = total14h.pricing + resa.pricing;
          break;
      }
    });

    setResas9h([...resa9h]);
    setResas10h([...resa10h]);
    setResas11h([...resa11h]);
    setResas14h([...resa14h]);

    // setTotal9h(total9h);
  };

  return (
    <div className="mx-3 w-full">
      {isLoggedContextState && currentUser.admin === 1 ? (
        <div id="wall" className="flex flex-row w-full overflow-y-scroll">
          <div className="w-[10%]">
            <LeftMenu />
          </div>
          <div className="w-[90%] border-2 z-0">
            <div className="text-center m-8 ">
              <DatePicker value={day} onChange={setDay} />
            </div>

            <div className=" rounded border-2 flex flex-col h-fit bg-white/50">
              <div className="flex justify-between rounded border-y-2 border-robins-egg-blue-50 m-8">
                <div className="p-6">
                  <h1>Canot restants :{inventory[0]?.canoe}</h1>
                </div>

                <div className="p-6">
                  <h1>Kayaks restants :{inventory[0]?.kayak}</h1>
                </div>

                <div className="p-6">
                  <h1>Sups restants :{inventory[0]?.sup}</h1>
                </div>
              </div>

              <div className=" rounded border-y-2 border-robins-egg-blue-50 m-8">
                <h2 className="text-electric-violet-900 bg-gray text-center">9 Heure</h2>
              </div>
              <div className=" rounded border-x-8 border-x-robins-egg-blue-900 m-8 -mt-8">
                <div className=" border-2 rounded border-r-2 border-x-electric-violet-400">
                  <Master key={uuidv4()} reservation={total9h} setResas={setResas} resas={resas} />
                </div>
                <div className="">
                  {resas9h.map((reservation: reservation) => (
                    <Master
                      key={uuidv4()}
                      reservation={reservation}
                      setResas={setResas}
                      resas={resas}
                    />
                  ))}
                </div>
              </div>

              <div className=" rounded border-y-2 border-robins-egg-blue-50 m-8">
                <h2 className="text-electric-violet-900 bg-gray text-center">10 Heure</h2>
              </div>
              <div className=" rounded border-x-8 border-x-robins-egg-blue-900 m-8 -mt-8">
                <div className=" border-2 rounded border-r-2 border-x-electric-violet-400">
                  <Master key={uuidv4()} reservation={total10h} setResas={setResas} resas={resas} />
                </div>
                <div className="">
                  {resas10h.map((reservation: reservation) => (
                    <Master
                      key={uuidv4()}
                      reservation={reservation}
                      setResas={setResas}
                      resas={resas}
                    />
                  ))}
                </div>
              </div>

              <div className=" rounded border-y-2 border-robins-egg-blue-50 m-8">
                <h2 className="text-electric-violet-900 bg-gray text-center">11 Heure</h2>
              </div>
              <div className=" rounded border-x-8 border-x-robins-egg-blue-900 m-8 -mt-8">
                <div className=" border-2 rounded border-r-2 border-x-electric-violet-400">
                  <Master key={uuidv4()} reservation={total11h} setResas={setResas} resas={resas} />
                </div>
                <div className="">
                  {resas11h.map((reservation: reservation) => (
                    <Master
                      key={uuidv4()}
                      reservation={reservation}
                      setResas={setResas}
                      resas={resas}
                    />
                  ))}
                </div>
              </div>

              <div className=" rounded border-y-2 border-robins-egg-blue-50 m-8">
                <h2 className="text-electric-violet-900 bg-gray text-center">14 Heure</h2>
              </div>
              <div className=" rounded border-x-8 border-x-robins-egg-blue-900 m-8 -mt-8">
                <div className=" border-2 rounded border-r-2 border-x-electric-violet-400">
                  <Master key={uuidv4()} reservation={total14h} setResas={setResas} resas={resas} />
                </div>
                <div className="">
                  {resas14h.map((reservation: reservation) => (
                    <Master
                      key={uuidv4()}
                      reservation={reservation}
                      setResas={setResas}
                      resas={resas}
                    />
                  ))}
                </div>
              </div>
              <br />
              <div className=" rounded border-x-8 border-x-robins-egg-blue-900 m-8 -mt-8">
                <div className=" border-2 rounded border-r-2 border-x-electric-violet-400">
                  <Master key={uuidv4()} reservation={total} setResas={setResas} resas={resas} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <NotFound />
        </div>
      )}
    </div>
  );
};

export default MasterList;
