import React, { ChangeEventHandler, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthProvider";
import { patchNewPackageText } from "../../services/packageService";
import { packages } from "../../types/packagesTypes";
import { UserContextType } from "../../types/userType";
import useEditTag from "./useEditTag";
import { patcheNewDefaultText } from "../../services/defaultContentService";
import { defaultContent } from "../../types/defaultContent";

type IProps = {
  selectedPackage: packages | defaultContent | any;
  attToModif: string;
  routeToPatch?: string;
};

const EditableTextPackage: React.FC<IProps> = ({
  selectedPackage,
  attToModif,
  routeToPatch = "package"
}) => {
  const [postText, setPostText] = useState(selectedPackage[attToModif]);
  const [postTextCancel, setTextCancel] = useState(selectedPackage[attToModif]);
  const { addTextAreaEditTag, removeTextAreaEditTag } = useEditTag();
  const refParentDiv = useRef<HTMLDivElement>(null);
  const { currentUser } = useContext(AuthContext) as UserContextType;

  let keysPressed: any = {};

  const changeText2WayBind: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setPostText(e.currentTarget.value);
  };
  const sendNewTextToApi = async (event: React.ChangeEvent<HTMLTextAreaElement> | any) => {
    keysPressed[event.key] = true;
    if (keysPressed["Control"] && event.key === "Enter") {
      if (routeToPatch === "package") {
        const packageToPatch: packages = {
          ...selectedPackage,
          [attToModif]: postText
        };
        patchNewPackageText(packageToPatch, currentUser);
      }
      if (routeToPatch === "textContent") {
        const textToPatch: defaultContent = {
          ...selectedPackage,
          [attToModif]: postText
        };
        patcheNewDefaultText(textToPatch, currentUser);
      }

      setTextCancel(postText);
      event.target.blur();
      window.location.reload();
    }
  };
  const keyUpResetMultipleKeys = async (event: React.ChangeEvent<HTMLTextAreaElement> | any) => {
    delete keysPressed[event.key];
  };

  const cancelchangePostTextOnEnter = (): void => {
    setPostText(postTextCancel);
  };

  useEffect(() => {
    setPostText(postTextCancel);
  }, [postTextCancel]);

  // useEffect(() => {
  //   refParentDiv.current!.className = "flex editableContain  h-[100%] w-[100%] rounded-lg ";
  // }, [parentDivWidth]);

  return (
    <div ref={refParentDiv} className="flex editableContain h-[100%] w-[100%] rounded-lg">
      <form>
        <textarea
          id="CommentsTextareaId"
          onMouseOver={addTextAreaEditTag}
          onMouseLeave={removeTextAreaEditTag}
          onBlur={cancelchangePostTextOnEnter}
          onKeyDown={sendNewTextToApi}
          onKeyUp={keyUpResetMultipleKeys}
          className="flex editableContain h-[100%] w-[100%] mr-60 rounded-lg "
          name="commentText"
          onChange={changeText2WayBind}
          value={postText}
        />
      </form>
    </div>
  );
};

export default EditableTextPackage;
