import React, { useContext } from "react";
import contactBg from "../../../src/images/contact.jpg";
import { faPhone, faEnvelope, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../mainContentComponent/Footer";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

const Contact: React.FC = () => {
  const { language } = useContext(AuthContext) as UserContextType;
  return (
    <div className="w-full">
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng"
              ? "Four Seasons Adventure: Contact us!"
              : "Aventure Quatre Saisons: Contactez-nous !"
          }
        />

        <meta
          name="description"
          content={
            language === "Eng"
              ? "In the Laurentians and Mauricie, For boating, whether it's canoeing, rafting, kayaking or stand-up paddleboarding (SUP)! Aventure Quatre Saisons is there for you!"
              : "Dans les Laurentides et en Mauricie, Pour le nautique, que ce soit pour faire du canot, du rafting, du kayak ou du stand-up paddleboard( SUP )!Aventure Quatre Saisons est là pour vous !"
          }
        />
        <title>
          {language === "Eng"
            ? "Four Seasons Adventure: Contact us!"
            : "Aventure Quatre Saisons: Contactez-nous !"}
        </title>
      </Helmet>
      <div className="bg-gradient-to-b to-aqs-green-500 from-aqs-green-200 z-20">
        <h1 className="hidden">
          {language === "Eng"
            ? "Four Seasons Adventure: Contact us!"
            : "Aventure Quatre Saisons: Contactez-nous !"}
        </h1>
        <div className="flex flex-col sm:flex-row">
          <div className="">
            <img
              src={contactBg}
              alt="Yourte sous les étoiles"
              style={{ width: "100%", height: "100%" }}
              className="sm:w-44"
            />
          </div>
          <div className="flex flex-col items-center justify-around text-6xl m-4">
            <div className="font-josefin font-extrabold">
              <h2>Contact</h2>
              <hr />
            </div>
            <div className="mb-16 text-sm sm:text-2xl">
              <div className="flex flex-row m-3">
                <div className="p-3">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="p-3">
                  <a href="tel:514-554-5091">514-554-5091</a>
                </div>
              </div>
              <div className="flex flox-row m-3">
                <div className="p-3">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className="p-3">
                  <a href="mailto:info@aventurequatresaisons.com">info@aventurequatresaisons.com</a>
                </div>
              </div>
              <div className="flex flex-row m-3">
                <div className="p-3">
                  <FontAwesomeIcon icon={faMap} />
                </div>
                <div className="p-3">
                  <a
                    href="http://maps.google.com/?q=4781 Chemin de la Rivière Nord Rivière-Rouge, QC J0T 1T0"
                    target="_blank"
                    rel="noreferrer">
                    4781 Chemin de la Rivière Nord Rivière-Rouge, QC J0T 1T0
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};

export default Contact;
