import React, { useContext, useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { getPackagesYourte } from "../../services/packageService";
import { yourteOneNight } from "../helper/defaultDataContent";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
  languageUrl?: string;
};

const YourteOneNight: React.FC<IProps> = ({ languageUrl = "", hideShowBookingBox }) => {
  const { setNewLanguage, language } = useContext(AuthContext) as UserContextType;
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(yourteOneNight);
  const title = language === "Eng" ? "Night in a yurt" : "Nuitée en Yourte";

  // ***-------------------Retrieving Activity-----------------------------------***
  useEffect(() => {
    if (languageUrl === "Eng") {
      setNewLanguage("Eng");
    } else {
      setNewLanguage("");
    }
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesYourte();
      setPackagesCanoeState(fetchedPackagesCanoe);
    } catch (err) {
      throw new Error(err as string);
    }
  };
  // END-------------------Retrieving Activity-----------------------------------***
  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng"
              ? "A Night in a Mongolian Yurt in Rivière-Rouge"
              : "Une Nuitée en Yourte Mongole à Rivière-Rouge"
          }
        />
        <meta
          name="Description"
          content={
            language === "Eng"
              ? "Come experience exoticism at its best! Enjoy a splendid night in one of our authentic Mongolian yurts."
              : "Venez vivre l’exotisme à son meilleuir! Profitez d'une splendide nuitée dans une de nos yourtes authentiques de Mongolie."
          }
        />

        <title>
          {language === "Eng"
            ? "One Night in a Mongolian Yurt in Rivière-Rouge."
            : "Une Nuitée en Yourte Mongole à Rivière-Rouge."}
        </title>
      </Helmet>
      <div className="z-20 w-full">
        <h1 className="hidden">
          {language === "Eng"
            ? "A Night in a Mongolian Yurt in Rivière-Rouge, near Mont-Tremblant in the Laurentians."
            : "Une Nuitée en Yourte Mongole à Rivière-Rouge, près de Mont-Tremblant dans les Laurentides."}
        </h1>
        <MainContentPage
          title={title}
          packagesCanoe={packagesCanoeState}
          hideShowBookingBox={hideShowBookingBox}></MainContentPage>
      </div>
    </div>
  );
};

export default YourteOneNight;
