import * as React from "react";
import { useEffect } from "react";
import { canoePackages } from "../components/helper/defaultDataContent";
import { getPackagesCards } from "../services/packageService";
import { packages, packagesContextType } from "../types/packagesTypes";

export const PackagesContext = React.createContext<packagesContextType | null>(null);

const PackagesProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [packagesContextState, setPackagesContextState] = React.useState<packages[]>(canoePackages);

  const getPackages = async () => {
    try {
      const packages: packages[] = await getPackagesCards();
      setPackagesContextState(packages);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);
  useEffect(() => {}, [packagesContextState]);

  return (
    <PackagesContext.Provider
      value={{
        packagesContextState
      }}>
      {children}
    </PackagesContext.Provider>
  );
};

export default PackagesProvider;
