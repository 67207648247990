import React, { useEffect, useRef } from "react";
import videoWebIntro from "../../videos/introweb.mp4";

const IntroVid: React.FC = () => {
  const refVideoBox = useRef<HTMLDivElement>(null);
  const refVideo = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    addEventListener("scroll", () => {
      showHideVideo();
    });
    addEventListener("click", () => {
      HideVideo();
    });
    addEventListener("mousemove", () => {
      playAfterXSec(0);
    });
  }, []);

  const playAfterXSec = async (secondsToPause: number) => {
    setTimeout(async () => {
      await refVideo.current?.play();
    }, secondsToPause * 1000);
  };

  const showHideVideo = async () => {
    if (window.scrollY < 5) {
      //Could put it back but intens
      // refVideoBox.current!.className = "absolute justify-center w-full -top-[1700px]  opacity-0  ";
      // setTimeout(() => {
      //   refVideoBox.current!.className =
      //     "absolute justify-center w-full top-36 sm:top-28  opacity-100  transition-all duration-1000 ease-in-out ";
      // }, 100);
    } else {
      refVideoBox.current!.className =
        "absolute justify-center w-full -top-[1700px] opacity-0  z-0 transition-all duration-1000  ease-in-out";
      setTimeout(() => {
        // refVideoBox.current!.className = " hidden";
      }, 1000);
    }
  };

  const HideVideo = async () => {
    refVideoBox.current!.className =
      "absolute justify-center w-full -top-[1700px] opacity-0   transition-all duration-1000  ease-in-out";
  };

  return (
    <div className="absolute justify-center w-full top-26 sm:top-16" ref={refVideoBox}>
      <video
        ref={refVideo}
        controls={false}
        src={videoWebIntro}
        loop={true}
        muted={true}
        autoPlay={false}
        height={"100%"}
        width={"100%"}
      />
    </div>
  );
};

export default IntroVid;
