import React from "react";
import PackageCard from "./PackageCard";
import { v4 as uuidv4 } from "uuid";
import { packages } from "../../../types/packagesTypes";

type IProps = {
  packageCard: packages[];
  // eslint-disable-next-line no-unused-vars
  selectPackageProps: (packageToSelect: packages) => void;
};

const PackageCards: React.FC<IProps> = ({ packageCard, selectPackageProps }) => {
  return (
    <div className="bg-aqs-blue-50/50 h-[670px] sm:h-auto sm:w-[40%] m-2 sm:m-4 border-[1px] border-white overflow-y-auto rounded-lg">
      <div className="flex flex-col">
        {packageCard.map((packageOne: packages) => (
          <PackageCard
            key={uuidv4()}
            packageOne={packageOne}
            selectPackageProps={selectPackageProps}
          />
        ))}
      </div>
    </div>
  );
};

export default PackageCards;
