import React from "react";
import facebook from "../../../src/images/facebook.svg";
import twitter from "../../../src/images/twitter.svg";
import instagram from "../../../src/images/instagram.svg";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className="bg-aqs-green-50/50 flex justify-center sm:w-auto">
      <div className="flex flex-col">
        <div className="flex sm:flex-row flex-col">
          <div className="border-2 border-white rounded-3xl m-5">
            <div className="text-xl m-3 ">
              <h2>Contact principal</h2>
            </div>
            <div className="text-sm m-2">
              <h2>Aventure Quatre Saisons</h2>
            </div>
            <div className="text-sm m-2">
              <a
                href="https://www.google.com/maps/place/Aventure+Quatre+Saisons/@46.4367728,-74.896988,18z/data=!4m14!1m2!2m1!1saventure+quatre+saisons!3m10!1s0x4ccf8fa6d66470e3:0x88b1bd06ef21c097!5m3!1s2023-08-21!4m1!1i2!8m2!3d46.4367728!4d-74.8946062!15sChdhdmVudHVyZSBxdWF0cmUgc2Fpc29ucyIDiAEBkgEXYWR2ZW50dXJlX3Nwb3J0c19jZW50ZXLgAQA!16s%2Fg%2F1tfkqd1n?entry=ttu"
                target="_blank"
                rel="noreferrer">
                4781 Chemin de la Rivière Nord Rivière-Rouge, QC J0T 1T0
              </a>
            </div>
            <div className="text-sm m-2">
              <a href="tel:514-554-5091">514-554-5091</a>
            </div>
            <div className="text-sm m-2">
              <a href="mailto:info@aventurequatresaisons.com">info@aventurequatresaisons.com</a>
            </div>
          </div>
          <div className="flex flex-col justify-between lg:w-[30%] border-2 border-white rounded-3xl m-5">
            <div className="text-xl m-3">
              <h3>Pourquoi nous choisir?</h3>
            </div>
            <div className="text-sm m-2">
              <h3>✓Service impécable</h3>
            </div>
            <div className="text-sm m-2">
              <h3>✓ Meilleur rapport qualité / prix</h3>
            </div>
            <div className="text-sm m-2">
              <h3> ✓ Guides passionnés du plein air </h3>
            </div>
            <div className="text-sm m-2">
              <h3> ✓ Embarcations de qualités</h3>
            </div>
            <div className="text-sm m-2">
              <h3> ✓ Site majestueux</h3>
            </div>
          </div>
          <div className=" border-2 border-white rounded-3xl m-5">
            <div className="text-xl m-3">
              <h3>Besoin d&apos;aide ?</h3>
            </div>
            <div className="text-sm m-2">
              <ul className="list-disc p-3">
                <a rel="noopener" href="#description">
                  <li>Comment faire une réservation ?</li>
                </a>
                <a rel="noopener" href="#bookingPolicy">
                  <li>Polituque d&apos;annulation et paiments</li>
                </a>
                <Link to="/contact">
                  <li>Contact</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="flex flex-col justify-between border-2 border-white rounded-3xl m-5">
            <div className="text-xl m-3">
              <h3>Suivez-nous!</h3>
            </div>
            <div className="text-sm m-2">
              <h3>Nos meilleurs rabais se retrouve tous sur nos réseaux</h3>
            </div>
            <div className="text-sm flex flex-row justify-center m-3">
              <a
                href="https://www.facebook.com/aventurequatresaisons/"
                target="_blank"
                rel="noreferrer">
                <img src={facebook} alt="Notre page Facebook" className="w-20 h-[100%]" />
              </a>
              <a href="https://twitter.com/aventure4saison/" target="_blank" rel="noreferrer">
                <img src={twitter} alt="Notre page Twitter" className="w-20 h-[100%]" />
              </a>
              <a
                href="https://www.instagram.com/aventurequatresaisons/"
                target="_blank"
                rel="noreferrer">
                <img src={instagram} alt="Notre page instagram" className="w-20 h-[100%]" />
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-around m-6">
          <p>
            Copyright © {new Date().getFullYear()} Aventure Quatre Saisons, Tous droits réservés.
          </p>
          <p>Powered by NOK</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
