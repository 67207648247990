import React, { useContext, useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import { getPackagesSled } from "../../services/packageService";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { sledVipPackages } from "../helper/defaultDataContent";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
  languageUrl?: string;
};

const TraineauChienVIP: React.FC<IProps> = ({ languageUrl = "", hideShowBookingBox }) => {
  const { setNewLanguage, language } = useContext(AuthContext) as UserContextType;
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(sledVipPackages);
  const title = language === "Eng" ? "VIP DogSledding" : "Traîneau à chiens VIP";

  // ***-------------------Retrieving Activity-----------------------------------***
  useEffect(() => {
    if (languageUrl === "Eng") {
      setNewLanguage("Eng");
    } else {
      setNewLanguage("");
    }
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesSled();
      setPackagesCanoeState(fetchedPackagesCanoe.reverse()); //Revers to put VIP in top
    } catch (err) {
      throw new Error(err as string);
    }
  };
  // END-------------------Retrieving Activity-----------------------------------***
  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content={
            language === "Eng"
              ? "Mont-Tremblant dog sledding VIP"
              : "Traîneau à chiens VIP Mont-Tremblant"
          }
        />

        <meta
          name="Description"
          content={
            language === "Eng"
              ? "Dog sledding, 1h45 from Montreal! The dogs roam the wooded trails and the field for a magical course worthy of our beautiful Laurentians."
              : "Le traineau à chiens, À 1h45 de Montréal! Les chiens sillonnent des sentiers boisés et le champ pour un parcours féérique digne de nos belles Laurentides."
          }
        />

        <title>
          {language === "Eng"
            ? "Mont-Tremblant dog sledding VIP"
            : "Traîneau à chiens VIP Mont-Tremblant"}
        </title>
      </Helmet>
      <div className="z-20 w-full">
        <h1 className="hidden">
          {language === "Eng"
            ? "VIP dog sledding Mont-Tremblant"
            : "Traineau a chiens Mont-Tremblant"}
        </h1>
        <MainContentPage
          title={title}
          packagesCanoe={packagesCanoeState}
          hideShowBookingBox={hideShowBookingBox}></MainContentPage>
      </div>
    </div>
  );
};

export default TraineauChienVIP;
