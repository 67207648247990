import React, { useContext, useEffect, useState } from "react";
import PackageDisplay from "./package/PackageDisplay";
import Title from "./Title";
import Description from "./Description";
import BookingPolicy from "./BookingPolicy";
import Comments from "./Comments";
import ContactFooter from "./ContactFooter";
import Footer from "./Footer";
import { packages } from "../../types/packagesTypes";
import { defaultContent } from "../../types/defaultContent";
import { getDefaultContents } from "../../services/defaultContentService";
import { contentTextDefault } from "../helper/defaultDataContent";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";
type IProps = {
  title: string;
  packagesCanoe: packages[];
  hideShowBookingBox: () => void;
};

const MainContentPage: React.FC<IProps> = ({ title, packagesCanoe, hideShowBookingBox }) => {
  // ***-------------------Retrieving mainContent-----------------------------------***

  const { language } = useContext(AuthContext) as UserContextType;

  const [fetchedTextContent, setFetchedTextContent] =
    useState<defaultContent[]>(contentTextDefault);

  const getDefaultContentsTexts = async () => {
    try {
      const fetchedDefaultContents: defaultContent[] = await getDefaultContents();

      const tmpDefaultContentByLanguage: any = [];

      if (language === "") {
        fetchedDefaultContents.forEach((content) => {
          if (!content.language.includes("en")) {
            tmpDefaultContentByLanguage.push(content);
          }
        });
      } else {
        fetchedDefaultContents.forEach((content) => {
          if (content.language.includes("en")) {
            tmpDefaultContentByLanguage.push(content);
          }
        });
      }

      setFetchedTextContent(tmpDefaultContentByLanguage as defaultContent[]);
    } catch (err) {
      throw new Error(err as string);
    }
  };

  useEffect(() => {
    getDefaultContentsTexts();
  }, []);
  useEffect(() => {
    getDefaultContentsTexts();
  }, [language]);

  useEffect(() => {}, [fetchedTextContent]);

  // END-------------------Retrieving mainContent-----------------------------------***
  return (
    <div>
      <div className="sm:m-8 duration-300 sm:w-auto">
        <div>
          <Title title={title}></Title>
        </div>
        <div
          id="displayPackageRef"
          className="flex h-auto sm:h-[700px] bg-white sm:w-[100%]  text-justify">
          <PackageDisplay
            packagesCanoe={packagesCanoe}
            hideShowBookingBox={hideShowBookingBox}></PackageDisplay>
        </div>
      </div>
      <div className="sm:m-8 sm:w-auto duration-300 p-2 text-justify">
        <Description
          defaultTexts={fetchedTextContent[0]}
          hideShowBookingBox={hideShowBookingBox}></Description>
      </div>
      <div className="sm:m-8  sm:w-auto hover:scale-[102%] p-2 duration-300 text-justify">
        <Comments defaultTexts={fetchedTextContent[2]}></Comments>
      </div>
      <div className="sm:m-8 sm:w-auto  hover:scale-[102%] p-2 duration-300  text-justify">
        <BookingPolicy defaultTexts={fetchedTextContent[1]}></BookingPolicy>
      </div>
      <div className="sm:m-8 sm:w-auto hover:scale-[102%] p-2 duration-300  text-justify">
        <ContactFooter></ContactFooter>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default MainContentPage;
