import * as React from "react";
import { useEffect, useState } from "react";
import { user, UserContextType } from "../types/userType";
import isLogged, { hardLogout } from "../services/authService";
import { getUserByUsername } from "../services/userService";
import { getCookie } from "../components/helper/getcookies";

export const AuthContext = React.createContext<UserContextType | null>(null);

const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [isLoggedContextState, setIsLoggedContextState] = React.useState<boolean>(false);
  const [currentUser, setUser] = useState<user>({
    id: "",
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    telephone: "",
    createdAt: 0,
    admin: 0
  });

  // ***-------------------Language------------------------------------***
  const [language, setLanguage] = useState<string>(localStorage.getItem("aqsLanguage") || "");

  const toggleLanguage = (): void => {
    setLanguage(language === "" ? "Eng" : "");
    localStorage.setItem("aqsLanguage", language === "" ? "Eng" : "");
  };

  const setNewLanguage = (newLanguage: string): void => {
    localStorage.setItem("aqsLanguage", newLanguage);
    setLanguage(newLanguage);
  };

  // END-------------------Language-----------------------------------***

  const getCurrentUser = async () => {
    try {
      const logged = await isLogged();
      if (logged) {
        const authUser: user = await getUserByUsername(
          localStorage.getItem("aimUsername") as string,
          localStorage.getItem("aimUserid") as string
        );
        setUser(authUser);
      } else {
        setIsLoggedContextState(false);
      }
    } catch (err) {
      hardLogout();
      window.open("/", "_self");
      throw new Error(err as string);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const updateUser = (updatedUser: user) => {
    setUser({
      id: updatedUser.id,
      username: updatedUser.username,
      firstName: updatedUser.firstName,
      lastName: updatedUser.lastName,
      email: updatedUser.email,
      telephone: updatedUser.telephone,
      createdAt: updatedUser.createdAt,
      admin: updatedUser.admin
    });
  };

  useEffect(() => {
    updateIsLogged();
  }, [currentUser]);

  const updateIsLogged = () => {
    if (getCookie("XSRF-TOKEN")) {
      setIsLoggedContextState(true);
    } else {
      setIsLoggedContextState(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isLoggedContextState,
        language,
        updateIsLogged,
        updateUser,
        toggleLanguage,
        setNewLanguage
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
