export function TalkToUser(message: string) {
  const divParent = document.getElementById("toastParent") as HTMLDivElement;
  const divMenu = document.getElementById("toast") as HTMLDivElement;
  const divMessage = document.getElementById("message") as HTMLDivElement;
  const divButton = document.getElementById("toastButton") as HTMLDivElement;

  divParent.className = "flex z-50 fixed justify-center top-1/3 overflow-hidden w-full ";

  divMenu.className =
    "text-white p-2 border-4 sm:w-2/6 border-x-orange border-y-red hover:bg-aqs-green-900/90 bg-aqs-green-700/90 rounded-xl";
  divMessage.innerText = message;

  document.addEventListener("click", (evt: MouseEvent) => {
    if (divButton.contains(evt.target as Node)) {
      divMenu.className =
        "text-white  p-2 border-4 sm:w-2/6 border-x-orange border-y-red hover:bg-aqs-green-900/90 bg-aqs-green-700/90 rounded-xl top-96 transition-opacity ease-out duration-1000 opacity-0";
      setTimeout(() => {
        divParent.className =
          "flex z-0 hidden fixed justify-center top-2/4 overflow-hidden  w-full";
      }, 1000);
    }
  });
}
