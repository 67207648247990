import React, { useContext } from "react";
import bgImage from "../../../src/images/bgtest.png";
import { defaultContent } from "../../types/defaultContent";
import EditableTextPackage from "../editableLiveForm/EditableTextPackage";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";
import { v4 as uuidv4 } from "uuid";

type IProps = {
  defaultTexts: defaultContent;
};

const DescriptionVenteYourte: React.FC<IProps> = ({ defaultTexts }) => {
  const { currentUser, isLoggedContextState } = useContext(AuthContext) as UserContextType;
  return (
    <div
      className="bg-gradient-to-r to-aqs-green-400 from-aqs-blue-500 text-base rounded-b-2xl rounded-3xl border-[1px] border-white overflow-hidden"
      id="description">
      <div className=" bg-cover text-white" style={{ backgroundImage: `url(${bgImage})` }}>
        {isLoggedContextState && currentUser.admin === 1 ? (
          <div className="flex flex-col items-center p-3 text-xl">
            <div className="text-5xl p-3">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"title"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="p-3 my-4">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"subtitle"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="p-3 my-4">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"description"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="p-3 my-4">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"descriptionFollowUp"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="text-5xl p-3">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"secondTitle"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="p-3 my-4">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"secondSubtitle"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="p-3 my-4">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"secondDescription"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="p-3 my-4">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"thirdDescription"}
                routeToPatch={"textContent"}
              />
            </div>
          </div>
        ) : (
          <div
            style={{ whiteSpace: "pre-line" }}
            className="flex flex-col items-center p-3 text-xl">
            <div className="text-5xl p-3">
              <h2>{defaultTexts.title}</h2>
            </div>
            <div className="p-3 my-4">
              <h3>{defaultTexts.subtitle}</h3>
            </div>
            <div className="p-3 my-4">
              <h4>{defaultTexts.description}</h4>
            </div>
            <div className="p-3 my-4">
              <h4>{defaultTexts.descriptionFollowUp}</h4>
            </div>
            <div className="text-5xl p-3">
              <h4>{defaultTexts.secondTitle}</h4>
            </div>
            <div className="p-3 my-4">
              <h4>{defaultTexts.secondSubtitle}</h4>
            </div>
            <div className="p-3 my-4">
              <h4>{defaultTexts.secondDescription}</h4>
            </div>
            <div className="p-3 my-4">
              <h4>{defaultTexts.thirdDescription}</h4>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DescriptionVenteYourte;
