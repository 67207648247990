import React, { useContext } from "react";
import { logout } from "../../services/authService";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

const Logout: React.FC = () => {
  const { currentUser } = useContext(AuthContext) as UserContextType;

  const logUserOut = (): void => {
    logout(currentUser);
  };

  return (
    <div
      onClick={logUserOut}
      className="flex h-10 w-20 justify-center border-2 p-2 m-1 hover:bg-aqs-blue-400/50 rounded-xl hover:cursor-pointer">
      <button>Logout</button>
    </div>
  );
};

export default Logout;
