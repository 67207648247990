import React, { useContext, useEffect, useState } from "react";
import PackageCards from "./PackageCards";
import PackagePreview from "./PackagePreview";
import { packages } from "../../../types/packagesTypes";
import { AuthContext } from "../../../contexts/AuthProvider";
import { UserContextType } from "../../../types/userType";

type IProps = {
  packagesCanoe: packages[];
  hideShowBookingBox: () => void;
};

const PackageDisplay: React.FC<IProps> = ({ packagesCanoe, hideShowBookingBox }) => {
  // ***-------------------Langugage Package Filter-----------------------------------***
  const { language } = useContext(AuthContext) as UserContextType;

  const [packageFilteredByLanguage, setPackageFilteredByLanguage] = useState<packages[]>([]);

  //Will flush all packages that are not in current language
  useEffect(() => {
    const tmpPackagesToSelect: any = [];

    if (language === "") {
      packagesCanoe.forEach((pack) => {
        if (!pack.activityType.includes("Eng")) {
          tmpPackagesToSelect.push(pack);
        }
      });
    } else {
      packagesCanoe.forEach((pack) => {
        if (pack.activityType.includes("Eng")) {
          tmpPackagesToSelect.push(pack);
        }
      });
    }

    setPackageFilteredByLanguage(tmpPackagesToSelect as packages[]);
  }, [packagesCanoe, language]);

  // End-------------------Langugage Package Filter-----------------------------------***

  // ***-------------------Selected Package-----------------------------------***
  const [selectedPackage, setselectedPackage] = useState<packages>(packagesCanoe[0]);

  useEffect(() => {
    setselectedPackage(packageFilteredByLanguage[0]);
  }, [packageFilteredByLanguage]);

  const selectPackage = (packageToSelect: packages): void => {
    setselectedPackage(packageToSelect);
  };
  // END-------------------Selected Package-----------------------------------***

  return (
    <div className="flex sm:flex-row flex-col bg-gradient-to-r to-aqs-green-400 from-aqs-blue-900 rounded-b-2xl">
      <PackagePreview
        selectedPackage={selectedPackage}
        hideShowBookingBox={hideShowBookingBox}></PackagePreview>
      <PackageCards
        packageCard={packageFilteredByLanguage}
        selectPackageProps={selectPackage}></PackageCards>
    </div>
  );
};

export default PackageDisplay;
