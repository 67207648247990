import React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

type IProps = {
  name: string;
  icon: FontAwesomeIconProps["icon"];
  size?: string;
};

const TopMenuItem: React.FC<IProps> = ({ name, icon, size = "text-xl" }) => {
  const classDynamic = "font-josefin m-2 pt-2 " + size;
  return (
    <div className="flex items-center cursor-pointer hover:bg-aqs-blue-300 duration-1000 m-1 sm:h-16 sm:w-30">
      <div className="p-1 m-3">
        <FontAwesomeIcon icon={icon} size="lg" />
      </div>
      <div className={classDynamic}>{name}</div>
    </div>
  );
};

export default TopMenuItem;
