import { getCookie } from "../components/helper/getcookies";
import { packages } from "../types/packagesTypes";
import { logout } from "./authService";
import { user } from "../types/userType";
import { TalkToUser } from "../components/helper/TalkToUser";

// const URL = "http://localhost:3010";
const URL = process.env.REACT_APP_API_BASE_URL;

export const getPackagesCards = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/allPackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesCardsSummer = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/summerActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesCardsWinter = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/winterActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesCardsYourte = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/yourteActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesCanoe = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/canoePackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesKayak = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/kayakPackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesSup = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/supPackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesSled = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/sledPackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesJorring = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/jorringPackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesYourte = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/yourtePackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const patchNewPackageText = async (payload: packages, user: user) => {
  const response = await fetch(`${URL}/packages/changePackageText/`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN"),
      "x-userid": user.id
    },
    body: JSON.stringify(payload)
  });
  if (response.status === 401) {
    alert("Vous avez perdus les authorization pour continuer / Signin again !");
    logout(user);
    window.open("/", "_self");
  }

  if (!response.ok) {
    const data = await response.json();
    TalkToUser(
      `Votre texte n'a pu être modifié! - Status :  ${response.status}, ${response.statusText}, ${data.message}`
    );
    throw new Error(data.message);
  }
  try {
    const data = await response.json();
    window.confirm(
      `Votre texte a pu être modifié! - Status :  ${response.status}, ${response.statusText}`
    );
    return data;
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesYourteSell = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/yourtePackagesSell/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};
