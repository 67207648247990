import React, { useContext, useEffect, useState } from "react";
import carte from "../../../src/images/carte.png";
import Title from "../mainContentComponent/Title";
import OpenBookingMenuButton from "../mainContentComponent/OpenBookingMenuButton";
import Comments from "../mainContentComponent/Comments";
import ContactFooter from "../mainContentComponent/ContactFooter";
import BookingPolicy from "../mainContentComponent/BookingPolicy";
import Footer from "../mainContentComponent/Footer";
import { defaultContent } from "../../types/defaultContent";
import { getDefaultContents } from "../../services/defaultContentService";
import Description from "../mainContentComponent/Description";
import { contentTextDefault } from "../helper/defaultDataContent";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  hideShowBookingBox: () => void;
  languageUrl?: string;
};

const Carte: React.FC<IProps> = ({ languageUrl = "", hideShowBookingBox }) => {
  const [fetchedTextContent, setFetchedTextContent] =
    useState<defaultContent[]>(contentTextDefault);
  // ***-------------------Retrieving MainContent-----------------------------------***

  const { setNewLanguage, language } = useContext(AuthContext) as UserContextType;
  useEffect(() => {
    if (languageUrl === "Eng") {
      setNewLanguage("Eng");
    } else {
      setNewLanguage("");
    }
    getDefaultContentsTexts();
  }, []);

  const getDefaultContentsTexts = async () => {
    try {
      const fetchedDefaultContents: defaultContent[] = await getDefaultContents();

      const tmpDefaultContentByLanguage: any = [];

      if (language === "") {
        fetchedDefaultContents.forEach((content) => {
          if (!content.language.includes("en")) {
            tmpDefaultContentByLanguage.push(content);
          }
        });
      } else {
        fetchedDefaultContents.forEach((content) => {
          if (content.language.includes("en")) {
            tmpDefaultContentByLanguage.push(content);
          }
        });
      }

      setFetchedTextContent(tmpDefaultContentByLanguage as defaultContent[]);
    } catch (err) {
      throw new Error(err as string);
    }
  };
  // END-------------------Retrieving MainContent-----------------------------------***

  useEffect(() => {}, [fetchedTextContent]);
  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content={language === "Eng" ? "Map of Rivière-Rouge" : "Carte de la Rivière-Rouge"}
        />
        <meta
          name="description"
          content={
            language === "Eng"
              ? "Tourist map of the Rivière-Rouge section of the Laurentians."
              : "Carte touristique de la Rivière-Rouge section des Laurentides."
          }
        />

        <title>{language === "Eng" ? "Map of Rivière-Rouge" : "Carte de la Rivière-Rouge"}</title>
      </Helmet>
      <div className="z-20">
        <h1 className="hidden">
          {language === "Eng"
            ? "Map of Rivière-Rouge in the Laurentians"
            : "Carte de la Rivière-Rouge dans les Laurentides"}
        </h1>
        <div className="sm:m-8 duration-300 sm:w-auto">
          <div>
            <Title
              title={
                language === "Eng" ? "Map of Rivière-Rouge" : "Carte de la Rivière-Rouge"
              }></Title>
          </div>
          <div className="flex justify-center">
            <OpenBookingMenuButton hideShowBookingBox={hideShowBookingBox}></OpenBookingMenuButton>
          </div>
        </div>
        <div className="flex text-2xl m-5 justify-center text-center">
          <h2>
            {language === "Eng"
              ? "Consult the different routes on this map of the Rivière-Rouge."
              : "Consultez les différents parcours sur cette carte de la rivière-rouge."}
          </h2>
        </div>
        <div className="m-16 hover:scale-[101%] duration-300 border-4 rounded-lg">
          <img src={carte} alt="Carte de la rivière rouge" />
        </div>
        <div className="sm:m-8 sm:w-auto duration-300 p-2 text-justify">
          <Description
            defaultTexts={fetchedTextContent[0]}
            hideShowBookingBox={hideShowBookingBox}></Description>
        </div>
        <div className="sm:m-8  sm:w-auto hover:scale-[102%] p-2 duration-300 text-justify">
          <Comments defaultTexts={fetchedTextContent[2]}></Comments>
        </div>
        <div className="sm:m-8 sm:w-auto  hover:scale-[102%] p-2 duration-300  text-justify">
          <BookingPolicy defaultTexts={fetchedTextContent[1]}></BookingPolicy>
        </div>
        <div className="sm:m-8 sm:w-auto hover:scale-[102%] p-2 duration-300  text-justify">
          <ContactFooter></ContactFooter>
        </div>
        <div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};

export default Carte;
