export const ParseInventToSelectItem = (item: string, available: number | undefined): string[] => {
  available = available === undefined ? 0 : available;
  const itemInSelect: string[] = [];

  //i= 1 : to never have 0 adultes see adjustment in Reserver.tsx ().
  if (item === "Adultes(s)") {
    for (let i = 1; i <= available; i++) {
      itemInSelect.push(`${i} ${item}`);
    }
  } else {
    for (let i = 0; i <= available; i++) {
      itemInSelect.push(`${i} ${item}`);
    }
  }

  return itemInSelect;
};
