import React from "react";
import { activities } from "../../types/activityType";
import ActMenuCard from "./ActMenuCard";
import { v4 as uuidv4 } from "uuid";

type IProps = {
  activities: activities[];
  mobileView?: boolean;
};

const MenuDropDown: React.FC<IProps> = ({ activities, mobileView = false }) => {
  return (
    <div>
      {mobileView ? (
        <div className="flex justify-center">
          <div className="flex flex-col w-[80%] items-center bg-aqs-blue-100 text-white border-[1px]">
            {activities.map((activity: activities) => (
              <ActMenuCard key={uuidv4()} activity={activity} mobileView={mobileView} />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex justify-center z-30">
          <div className="flex flex-row flex-wrap justify-center bg-aqs-green-500/20 border-aqs-green-300 border-2 ">
            {activities.map((activity: activities) => (
              <ActMenuCard key={uuidv4()} activity={activity} mobileView={mobileView} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuDropDown;
