import { getCookie } from "../components/helper/getcookies";
import { TalkToUser } from "../components/helper/TalkToUser";
import { defaultContent } from "../types/defaultContent";
import { user } from "../types/userType";

// const URL = "http://localhost:3010";
// eslint-disable-next-line no-undef
const URL = process.env.REACT_APP_API_BASE_URL;

export const getDefaultContents = async <defaultContent>(): Promise<defaultContent[]> => {
  const response = await fetch(`${URL}/defaultContents/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const patcheNewDefaultText = async (payload: defaultContent, user: user) => {
  const response = await fetch(`${URL}/defaultContents/`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN"),
      "x-userid": user.id
    },
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    const data = await response.json();
    TalkToUser(
      `Votre texte n'a pu être modifié! - Status :  ${response.status}, ${response.statusText}, ${data.message}`
    );
    throw new Error(data.message);
  }
  try {
    const data = await response.json();
    window.confirm(
      `Votre texte a pu être modifié! - Status :  ${response.status}, ${response.statusText}`
    );

    window.location.reload();
    return data;
  } catch (err) {
    throw new Error(err as string);
  }
};
