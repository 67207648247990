import React from "react";

export default function useEditTag() {
  // Show editTag for text area field For POST description
  const addTextAreaEditTag = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    const parent: HTMLDivElement = event.currentTarget.parentNode?.parentNode as HTMLDivElement;
    const editTag = document.createElement("input");
    editTag.setAttribute("type", "text");
    editTag.value = "Edit then -CTRL-ENTER-";
    editTag.id = "editTagId";
    editTag.className = "absolute border-[1px] text-black -ml-40 font-josefin text-xs";
    parent.appendChild(editTag);
  };

  // Hide editTag for text area field For POST description
  // eslint-disable-next-line no-unused-vars
  const removeTextAreaEditTag = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    const editTagElement = document.getElementById("editTagId") as HTMLInputElement;
    if (editTagElement) {
      editTagElement.remove();
    }
  };

  return {
    addTextAreaEditTag,
    removeTextAreaEditTag
  };
}
