import React, { useContext } from "react";
import { defaultContent } from "../../types/defaultContent";
import EditableTextPackage from "../editableLiveForm/EditableTextPackage";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";

type IProps = {
  defaultTexts: defaultContent;
};

const BookingPolicy: React.FC<IProps> = ({ defaultTexts }) => {
  const { currentUser, isLoggedContextState } = useContext(AuthContext) as UserContextType;
  return (
    <div
      className="flex flex-col bg-gradient-to-r to-aqs-green-400 from-aqs-blue-500 rounded-b-2xl rounded-3xl text-white"
      id="bookingPolicy">
      {isLoggedContextState && currentUser.admin === 1 ? (
        <div className="m-3 p-5 flex justify-center">
          <div className="flex flex-wrap flex-col bg-aqs-blue-50/50 border-[1px] border-white rounded-xl h-[70%]">
            <div className="m-3 p-3 font-josefin font-extrabold text-center text-3xl">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"title"}
                routeToPatch={"textContent"}
              />
            </div>
            <hr className="mx-10" />
            <div className="m-3 p-3 font-josefin font-extrabold text-center text-2xl">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"subtitle"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="m-3 p-3">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"description"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="m-3 p-3">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"descriptionFollowUp"}
                routeToPatch={"textContent"}
              />
            </div>
            <hr className="mx-10" />
            <div className="m-3 p-3 font-josefin font-extrabold text-center text-2xl">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"secondTitle"}
                routeToPatch={"textContent"}
              />
            </div>
            <div className="m-3 p-3">
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={defaultTexts}
                attToModif={"secondDescription"}
                routeToPatch={"textContent"}
              />
            </div>
          </div>
        </div>
      ) : (
        <div style={{ whiteSpace: "pre-line" }} className="m-3 p-5 flex justify-center">
          <div className="flex flex-wrap flex-col bg-aqs-blue-50/50 border-[1px] border-white rounded-xl h-[70%]">
            <div className="m-3 p-3 font-josefin font-extrabold text-center text-3xl">
              <h2>{defaultTexts?.title}</h2>
            </div>
            <hr className="mx-10" />
            <div className="m-3 p-3 font-josefin font-extrabold text-center text-2xl">
              <h3>{defaultTexts?.subtitle}</h3>
            </div>
            <div className="m-3 p-3">
              <h3>{defaultTexts?.description}</h3>
            </div>
            <div className="m-3 p-3">
              <h3>{defaultTexts?.descriptionFollowUp}</h3>
            </div>
            <hr className="mx-10" />
            <div className="m-3 p-3 font-josefin font-extrabold text-center text-2xl">
              <h3>{defaultTexts?.secondTitle}</h3>
            </div>
            <div className="m-3 p-3">
              <h3>{defaultTexts?.secondDescription}</h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingPolicy;
