import React, { useState, useContext } from "react";
import { deleteResaDb } from "../../services/bookingService";
import { reservation } from "../../types/reservationTypes";
import EditableInputFirstName from "../editableLiveForm/EditableInputFirstName";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../contexts/AuthProvider";
import { UserContextType } from "../../types/userType";
import { PackagesContext } from "../../contexts/PackagesProvider";
import { packages, packagesContextType } from "../../types/packagesTypes";

type IProps = {
  reservation: reservation;
  // eslint-disable-next-line no-unused-vars
  setResas: (resas: reservation[]) => void;
  resas: reservation[];
};

const Resa: React.FC<IProps> = ({ reservation, setResas, resas }) => {
  const [reseration] = useState<reservation>(reservation);
  const [prixTxIn] = useState(reseration.pricing * 1.15);
  const { currentUser } = useContext(AuthContext) as UserContextType;
  const { packagesContextState } = useContext(PackagesContext) as packagesContextType;

  const deleteResa = async () => {
    await deleteResaDb(reseration.ID, currentUser.id);
    alert(`La réservation ID : ${reseration.ID} à été supprimé !`);
    resas.forEach((resa, index) => {
      if (resa.ID === reseration.ID) {
        resas.splice(index, 1);
      }
    });

    setResas([...resas]);
  };

  return (
    <div className="flex flex-row bg-white rounded-xl m-3 p-3">
      <div className="w-[30%] pt-10">
        {packagesContextState.map((pack: packages) =>
          pack.tag === reseration.activite ? (
            <img key={uuidv4()} className="h-auto w-full p-4" src={pack.picUrl} alt={pack.name} />
          ) : (
            <div key={uuidv4()}></div>
          )
        )}
      </div>
      <div className="flex flex-col w-[70%]">
        <div className="flex justify-end -mb-4">
          <FontAwesomeIcon onClick={deleteResa} icon={faTrash} />
        </div>

        <div className="flex flex-wrap justify-around rounded border-2 bg-aqs-blue-300/30 border-aqs-green-400 m-4">
          <div className="font-yeseva text-2xl m-2">
            <EditableInputFirstName
              key={uuidv4()}
              resaId={reseration.ID}
              fieldToChange={"startDate"}
              newValue={reseration.startDate}
            />
          </div>
          <div className="font-yeseva text-2xl m-2 text-blue bg-robins-egg-blue-800/20 rounded-md ">
            <EditableInputFirstName
              key={uuidv4()}
              resaId={reseration.ID}
              fieldToChange={"activite"}
              newValue={reseration.activite}
            />
          </div>
          <div className="font-yeseva text-2xl m-2">
            <EditableInputFirstName
              key={uuidv4()}
              resaId={reseration.ID}
              fieldToChange={"name"}
              newValue={reseration.name}
            />
          </div>
          <div className="font-yeseva text-2xl m-2">
            <EditableInputFirstName
              key={uuidv4()}
              resaId={reseration.ID}
              fieldToChange={"prename"}
              newValue={reseration.prename}
            />
          </div>
        </div>

        <div className="flex flex-wrap justify-around rounded border-2 bg-aqs-blue-300/10 border-aqs-green-400 m-4">
          <div className="font-yeseva text-1xl m-2">
            <p>
              mail :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={reseration.ID}
                fieldToChange={"courriel"}
                newValue={reseration.courriel}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl m-2">
            <p>
              tel :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={reseration.ID}
                fieldToChange={"tel"}
                newValue={reseration.tel}
              />
            </p>
          </div>
        </div>
        <div className="flex justify-center m-4">
          <div className="flex flex-row w-full rounded border-2 bg-aqs-blue-300/10 border-aqs-green-400 mr-24">
            <div className="font-yeseva text-1xl mr-4 flex justify-center w-full m-2">
              <p className="flex flex-row">
                Départ :{" "}
                <EditableInputFirstName
                  key={uuidv4()}
                  resaId={reseration.ID}
                  fieldToChange={"departureTime"}
                  newValue={reseration.departureTime.toString()}
                />{" "}
                H
              </p>
            </div>
            <div className="font-yeseva text-1xl mr-4 flex justify-center w-full m-2">
              <p className="flex flex-row">
                Durée :{" "}
                <EditableInputFirstName
                  key={uuidv4()}
                  resaId={reseration.ID}
                  fieldToChange={"duree"}
                  newValue={reseration.duree.toString()}
                />{" "}
                jour(s)
              </p>
            </div>
          </div>
          <div className="flex flex-row w-full rounded border-2 bg-aqs-blue-300/10 border-aqs-green-400">
            <div className="font-yeseva text-1xl mr-4 flex justify-center w-full m-2">
              <p className="flex flex-row">
                Adultes :{" "}
                <EditableInputFirstName
                  key={uuidv4()}
                  resaId={reseration.ID}
                  fieldToChange={"nbPaxAd"}
                  newValue={reseration.nbPaxAd.toString()}
                />
              </p>
            </div>
            <div className="font-yeseva text-1xl mr-4 flex justify-center w-full m-2">
              <p className="flex flex-row">
                Enfants :
                <EditableInputFirstName
                  key={uuidv4()}
                  resaId={reseration.ID}
                  fieldToChange={"nbPaxEnf"}
                  newValue={reseration.nbPaxEnf.toString()}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-around rounded border-2 bg-aqs-blue-300/10 border-aqs-green-400 m-4">
          <div className="font-yeseva text-1xl ">
            <p className="flex flex-row m-2">
              canot(s) :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={reseration.ID}
                fieldToChange={"nbCanoe"}
                newValue={reseration.nbCanoe.toString()}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl ">
            <p className="flex flex-row m-2">
              kayak(s) :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={reseration.ID}
                fieldToChange={"nbKayak"}
                newValue={reseration.nbKayak.toString()}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl ">
            <p className="flex flex-row m-2">
              sup(s) :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={reseration.ID}
                fieldToChange={"nbSUP"}
                newValue={reseration.nbSUP.toString()}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl">
            <p className="flex flex-row m-2">
              yourte(s) :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={reseration.ID}
                fieldToChange={"yourte"}
                newValue={reseration.yourte}
              />
            </p>
          </div>
        </div>
        <div className="flex justify-center m-4">
          <div className="flex flex-wrap rounded border-2 bg-aqs-blue-300/10 border-aqs-green-400 w-[50%] justify-around">
            <div className="font-yeseva text-1xl mr-4">
              <p className="flex flex-row m-2">
                Total($) :{" "}
                <EditableInputFirstName
                  key={uuidv4()}
                  resaId={reseration.ID}
                  fieldToChange={"pricing"}
                  newValue={reseration.pricing.toString()}
                />
              </p>
            </div>
            <div className="font-yeseva text-1xl mr-4">
              <p className="flex flex-row m-2">
                TxsIn($) :{" "}
                <EditableInputFirstName
                  key={uuidv4()}
                  resaId={reseration.ID}
                  fieldToChange={"-"}
                  newValue={prixTxIn.toFixed(2).toString()}
                />
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap rounded border-2 bg-aqs-blue-300/10 border-aqs-green-400 m-4 justify-around">
          <div className="font-yeseva text-1xl mr-4">
            <p className="m-2">
              Date Résa :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={reseration.ID}
                fieldToChange={"confDate"}
                newValue={reseration.confDate}
              />
            </p>
          </div>
          <div className="font-yeseva text-1xl mr-4">
            <p className="m-2">
              ID Résa :{" "}
              <EditableInputFirstName
                key={uuidv4()}
                resaId={reseration.ID}
                fieldToChange={"ID"}
                newValue={reseration.ID}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resa;
