import { TalkToUser } from "../components/helper/TalkToUser";
import { reservation } from "../types/reservationTypes";
import { getCookie } from "../components/helper/getcookies";

// const URL = "http://localhost:3010";
const URL = process.env.REACT_APP_API_BASE_URL;

export const newBooking = async (newresa: reservation): Promise<reservation> => {
  //Take out the Eng for english package to fit french email in back end
  newresa.activite = newresa.activite.replace("Eng", "");
  console.log(newresa);
  const response = await fetch(`${URL}/reservation`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(newresa)
  });
  if (response.status === 401) {
    TalkToUser("Vous avez perdus les authorizations pour continuer / Try again Later!");
  }

  try {
    const data = await response.json();
    if (!response.ok) {
      TalkToUser(
        `Votre Réservation ne peut-être ajoutée! - Status :  ${response.status}, ${response.statusText}, ${data.message}`
      );
      // window.open("/", "_self");
    } else {
      TalkToUser("Votre Réservation est envoyée !");
      // window.open("/", "_self");
    }
    return data;
  } catch (err) {
    const data = await response.json();
    TalkToUser(
      `Votre Réservation ne peut-être ajoutée! - Status :  ${response.status}, ${response.statusText}, ${data.message}`
    );
    throw new Error(err as string);
  }
};

export const getFullResa = async <reservation>(): Promise<reservation[]> => {
  const response = await fetch(`${URL}/reservation`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const deleteResaDb = async (resaId: string, currentUser: string) => {
  const response = await fetch(`${URL}/reservation/${resaId}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN"),
      "x-userid": currentUser
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
};

export const getResaDate = async <reservation>(date: string): Promise<reservation[]> => {
  const response = await fetch(`${URL}/reservation/onedate/${date}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const patchResaInfo = async <reservation>(
  resaID: string,
  newInfo: reservation,
  currentUser: string
) => {
  // Ajouter le ID ou patche by name du UserContext
  const payload = { ...newInfo, ID: resaID };
  const response = await fetch(`${URL}/reservation/${resaID}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "x-xsrf-token": getCookie("XSRF-TOKEN"),
      "x-userid": currentUser
    },
    body: JSON.stringify(payload)
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    alert(`Votre modification à été apporté ! nouvel Entrée : ${Object.values(newInfo)[0]}`);
  }
};
// function getCookie(arg0: string): string {
//   throw new Error("Function not implemented." + arg0);
// }
