import React from "react";
import { Link } from "react-router-dom";
import { faHandHoldingUsd, faStore, faTools } from "@fortawesome/free-solid-svg-icons";
import LeftMenuItem from "./LeftMenuItem";

const LeftMenu: React.FC = () => {
  return (
    <div className="flex flex-col border-2 h-full">
      <div className="font-josefin text-xl mb-2 text-center">Menu</div>
      <Link to="/fr/laurentides/riviere-rouge/merchant/inventory">
        <LeftMenuItem icon={faStore} name="Inventaire" />
      </Link>
      <Link to="/fr/laurentides/riviere-rouge/merchant/booking">
        <LeftMenuItem icon={faHandHoldingUsd} name="Réservations" />
      </Link>
      <Link to="/fr/laurentides/riviere-rouge/merchant/master">
        <LeftMenuItem icon={faTools} name="Master" />
      </Link>
    </div>
  );
};

export default LeftMenu;
