import React from "react";
import { faPhone, faEnvelope, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactFooter: React.FC = () => {
  return (
    <div className="flex justify-center bg-gradient-to-r to-aqs-green-200/50 from-aqs-green-500/50 rounded-b-2xl rounded-3xl">
      <div className=" flex justify-center flex-col">
        <div className="flex justify-center font-josefin font-extrabold text-3xl m-3">
          <h2>Contact</h2>
        </div>
        <div className=" flex sm:flex-row justify-center text-xl flex-col">
          <div className="flex flex-row m-3">
            <div className="m-1">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="m-1">
              <a href="tel:514-554-5091">514-554-5091</a>
            </div>
          </div>
          <div className="flex flex-row m-3">
            <div className="m-1">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="m-1">
              <a href="mailto:info@aventurequatresaisons.com">info@aventurequatresaisons.com</a>
            </div>
          </div>
          <div className="flex flex-row m-3">
            <div className="m-1">
              <FontAwesomeIcon icon={faMap} />
            </div>
            <div className="m-1">
              <a
                href="https://www.google.com/maps/place/Aventure+Quatre+Saisons/@46.4367728,-74.896988,18z/data=!4m14!1m2!2m1!1saventure+quatre+saisons!3m10!1s0x4ccf8fa6d66470e3:0x88b1bd06ef21c097!5m3!1s2023-08-21!4m1!1i2!8m2!3d46.4367728!4d-74.8946062!15sChdhdmVudHVyZSBxdWF0cmUgc2Fpc29ucyIDiAEBkgEXYWR2ZW50dXJlX3Nwb3J0c19jZW50ZXLgAQA!16s%2Fg%2F1tfkqd1n?entry=ttu"
                target="_blank"
                rel="noreferrer">
                4781 Chemin de la Rivière Nord Rivière-Rouge, QC J0T 1T0
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFooter;
